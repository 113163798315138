import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import black_logo from "../assets/xr_logo_black.png";
import { FaBed } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { IoMdPerson } from "react-icons/io";

function ProjectImagesOld({ imagesWithInfo, viewAll, newsImages }) {
  const icons = [<FaBed />, <IoMdPerson />, <HiLocationMarker />];

  // let UpdatedImagesWithInfo = viewAll
  //   ? imagesWithInfo
  //   : imagesWithInfo?.slice(0, 3);

  let UpdatedImagesWithInfo;
  if (!viewAll) {
    // if (isSmallScreen || isLargeScreen) {
    //   UpdatedImagesWithInfo = imagesWithInfo?.slice(0, 4);
    // } else if (isMediumScreen) {
    //   UpdatedImagesWithInfo = imagesWithInfo?.slice(0, 4);
    // }
    UpdatedImagesWithInfo = imagesWithInfo?.slice(0, 4);
  } else {
    UpdatedImagesWithInfo = imagesWithInfo;
  }

  if (newsImages) {
    UpdatedImagesWithInfo = imagesWithInfo;
  }

  // const Card = ({ imageWithInfo }) => (
  //   <div className="relative h-auto flex-none pt-4 4xl:h-auto">
  //     <div className="relative h-full border border-gray-300">
  //       <div className="overflow-hidden">
  //         <Link
  //           reloadDocument
  //           to={`/property/${
  //             newsImages
  //               ? imageWithInfo?.slug
  //               : imageWithInfo?.property_name_slug
  //           }/`}
  //         >
  //           <img
  //             alt=""
  //             src={imageWithInfo?.images[0]?.url}
  //             className="aspect-video w-full object-fill md:object-fill lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
  //           />
  //         </Link>
  //       </div>

  //       <img
  //         alt="Clip"
  //         src={black_logo}
  //         className="absolute -top-2 right-2 h-5 w-5 md:-top-3 md:right-3 md:h-7 md:w-7 bg-white shadow-sm"
  //       />

  //       <div className="p-4 overflow-hidden">
  //         <dl>
  //           <div>
  //             <dt className="sr-only">Title</dt>
  //             <dd className="text-xs md:text-sm text-black font-bold">
  //               {newsImages
  //                 ? imageWithInfo?.title
  //                 : imageWithInfo?.property_name.toUpperCase()}
  //             </dd>
  //           </div>
  //           <div>
  //             <dd className="text-xs md:text-xs font-light text-gray-700">
  //               {newsImages
  //                 ? imageWithInfo?.images[0]?.description
  //                 : `STARTING FROM ${imageWithInfo?.price}`}
  //             </dd>
  //           </div>
  //         </dl>

  //         <div className="md:mt-2 lg:mt-3 items-center lg:gap-2 gap-4 sm:gap-1">
  //           <div className="sm:shrink-0 sm:items-center sm:gap-x-2 md:text-xs lg:text-xl text-gray-500 flex-wrap flex gap-1">
  //             {imageWithInfo?.type[0]?.bedrooms && (
  //               <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
  //                 <div>{icons[0]}</div>
  //                 <p className="font-light ">
  //                   {imageWithInfo?.type[0]?.bedrooms}
  //                 </p>
  //               </div>
  //             )}
  //             {imageWithInfo?.developer && (
  //               <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
  //                 <div>{icons[1]}</div>
  //                 <p className="font-light">
  //                   {imageWithInfo?.developer.toUpperCase()}
  //                 </p>
  //               </div>
  //             )}
  //             {imageWithInfo?.community_name && (
  //               <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
  //                 <div>{icons[2]}</div>
  //                 <p className="font-light">
  //                   {imageWithInfo?.community_name?.toUpperCase()}
  //                 </p>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const Card = ({ imageWithInfo }) => (
    <div className="relative h-auto flex-none pt-4 4xl:h-auto transition-transform hover:-translate-y-2 hover:shadow-2xl">
      <div className="relative h-full border border-gray-300 rounded-lg overflow-visible shadow-lg">
        <div className="overflow-hidden rounded-t-lg">
          <Link
            reloadDocument
            to={`/property/${
              newsImages
                ? imageWithInfo?.slug
                : imageWithInfo?.property_name_slug
            }/`}
          >
            <img
              alt={
                newsImages ? imageWithInfo?.title : imageWithInfo?.property_name
              }
              src={imageWithInfo?.images[0]?.url}
              className="aspect-video w-full object-cover md:object-fill transform hover:scale-105 transition-transform duration-1000"
            />
          </Link>
        </div>

        {/* Logo Clip */}
        {/* <img
          alt="Clip"
          src={black_logo}
          className="absolute -top-4 right-2 h-8 w-8 md:-top-3 md:right-3 md:h-10 md:w-10 bg-white shadow-md rounded-sm border border-gray-300"
        /> */}

        <img
          alt="Clip"
          src={black_logo}
          className="absolute -top-3 right-2 h-6 w-6 md:-top-4 md:right-3 md:h-8 md:w-8 bg-white shadow-md rounded-sm border border-gray-300"
        />

        <div className="p-4">
          <dl>
            <div>
              <dt className="sr-only">Title</dt>
              <dd className="text-sm md:text-base font-bold text-gray-800 truncate">
                {newsImages
                  ? imageWithInfo?.title
                  : imageWithInfo?.property_name.toUpperCase()}
              </dd>
            </div>
            <div>
              <dd className="text-xs md:text-sm font-light text-gray-600 truncate">
                {newsImages
                  ? imageWithInfo?.images[0]?.description
                  : `STARTING FROM ${imageWithInfo?.price}`}
              </dd>
            </div>
          </dl>

          <div className="flex items-center mt-3 space-x-4 text-gray-500 flex-wrap">
            {imageWithInfo?.type[0]?.bedrooms && (
              <div className="flex items-center gap-1 text-xs md:text-sm">
                <div>{icons[0]}</div>
                <p className="font-light">{imageWithInfo?.type[0]?.bedrooms}</p>
              </div>
            )}
            {imageWithInfo?.developer && (
              <div className="flex items-center gap-1 text-xs md:text-sm">
                <div>{icons[1]}</div>
                <p className="font-light">
                  {imageWithInfo?.developer.toUpperCase()}
                </p>
              </div>
            )}
            {imageWithInfo?.community_name && (
              <div className="flex items-center gap-1 text-xs md:text-sm">
                <div>{icons[2]}</div>
                <p className="font-light">
                  {imageWithInfo?.community_name.toUpperCase()}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    // <div>
    //   <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
    //     {UpdatedImagesWithInfo?.map((imageWithInfo, index) => (
    //       <div
    //         key={index}
    //         className="relative h-auto flex flex-col border border-gray-300  overflow-visible"
    //       >
    //         <div className="overflow-hidden">
    //           <Link
    //             reloadDocument
    //             to={`/property/${
    //               newsImages
    //                 ? imageWithInfo?.slug
    //                 : imageWithInfo?.property_name_slug
    //             }/`}
    //           >
    //             <img
    //               alt=""
    //               src={imageWithInfo?.images[0]?.url}
    //               className="aspect-video w-full object-cover lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
    //             />
    //           </Link>
    //         </div>

    //         <img
    //           alt="Clip"
    //           src={black_logo}
    //           className="absolute -top-2 right-2 h-5 w-5 md:-top-3 md:right-3 md:h-7 md:w-7 bg-white shadow-sm"
    //         />
    //         <div className="p-4 overflow-hidden ">
    //           <dl>
    //             <div>
    //               <dt className="sr-only">Title</dt>
    //               <dd className="text-xs md:text-base text-black font-bold">
    //                 {newsImages
    //                   ? imageWithInfo?.title
    //                   : imageWithInfo?.property_name.toUpperCase()}
    //               </dd>
    //             </div>
    //             <div>
    //               <dd className="text-xs md:text-sm font-light text-gray-700">
    //                 {newsImages
    //                   ? imageWithInfo?.images[0]?.description
    //                   : `STARTING FROM ${imageWithInfo?.price}`}
    //               </dd>
    //             </div>
    //           </dl>

    //           <div className="md:mt-2 lg:mt-3 items-center lg:gap-2 gap-4 sm:gap-1">
    //             <div className="sm:shrink-0 sm:items-center sm:gap-x-2 md:text-base lg:text-xl text-gray-500 flex-wrap flex gap-1">
    //               {imageWithInfo?.type[0]?.bedrooms && (
    //                 <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
    //                   <div>{icons[0]}</div>
    //                   <p className="font-light">
    //                     {imageWithInfo?.type[0]?.bedrooms}
    //                   </p>
    //                 </div>
    //               )}
    //               {imageWithInfo?.developer && (
    //                 <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
    //                   <div>{icons[1]}</div>
    //                   <p className="font-light">
    //                     {imageWithInfo?.developer.toUpperCase()}
    //                   </p>
    //                 </div>
    //               )}
    //               {imageWithInfo?.community_name && (
    //                 <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
    //                   <div>{icons[2]}</div>
    //                   <p className="font-light">
    //                     {imageWithInfo?.community_name?.toUpperCase()}
    //                   </p>
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-8">
      {UpdatedImagesWithInfo?.map((imageWithInfo, index) => (
        <Card key={index} imageWithInfo={imageWithInfo} />
      ))}
    </div>
  );
}

export default ProjectImagesOld;
