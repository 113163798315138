import React, { useState } from "react";
import ReactPlayer from "react-player";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Recommendations = ({ videos, onVideoClick }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="overflow-y-auto">
      {/* Accordion for small and medium devices */}
      <div className="lg:hidden">
        <button
          className="w-full text-left p-2 bg-black text-white font-bold flex items-center justify-between"
          onClick={toggleAccordion}
        >
          <span>
            {isAccordionOpen ? "Hide Videos" : "Show Recommended Videos"}
          </span>
          {isAccordionOpen ? (
            <FaChevronUp className="text-white" />
          ) : (
            <FaChevronDown className="text-white" />
          )}
        </button>
        {isAccordionOpen && (
          <div className="flex flex-col mt-2 overflow-auto max-h-[50vh]">
            {videos.map((video, index) => (
              <div
                key={index}
                className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-2 gap-2 cursor-pointer hover:bg-gray-900 p-2"
                onClick={() => onVideoClick(video)}
              >
                <div className="aspect-video col-span-1 md:col-span-2 lg:w-full lg:col-span-1 relative ">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={video.url}
                    playing={true}
                    volume={0}
                    muted={true}
                    light={true}
                  />
                  <div
                    className="absolute inset-0"
                    onClick={() => onVideoClick(video)}
                  />
                </div>
                <div className="flex flex-col col-span-2 justify-center py-1 md:col-span-4 lg:col-span-1 md:ml-2 ">
                  <div>
                    <h3 className="text-white text-sm md:text-lg lg:text-sm font-semibold line-clamp-1">
                      {video.title}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* For large devices */}
      <div className="hidden lg:flex lg:flex-col lg:gap-4">
        {videos.slice(0, 6).map((video, index) => (
          <div
            key={index}
            className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3 cursor-pointer hover:bg-gray-900 pl-4"
            onClick={() => onVideoClick(video)}
          >
            <div className="aspect-video col-span-1 md:col-span-2 lg:h-full lg:col-span-1 relative">
              <ReactPlayer
                width="100%"
                height="100%"
                url={video.url}
                playing={true}
                volume={0}
                muted={true}
                light={true}
              />
              <div
                className="absolute inset-0"
                onClick={() => onVideoClick(video)}
              />
            </div>
            <div className="flex flex-col col-span-2 justify-center py-1 md:col-span-4 lg:col-span-2 md:ml-2">
              <div>
                <h3 className="text-white text-sm md:text-lg lg:text-sm font-semibold line-clamp-2">
                  {video.title}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
