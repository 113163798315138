import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// ReactDOM.render(<App />, document.getElementById("root"));
// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(<App />, rootElement);
// } else {
//   ReactDOM.render(<App />, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();

// import { hydrateRoot, createRoot } from "react-dom/client";
// import React from "react";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const root = document.getElementById("root");
// if (root.hasChildNodes()) {
//   hydrateRoot(
//     root,
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// } else {
//   createRoot(root).render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// reportWebVitals();
