import React from "react";
import { IoMdPerson } from "react-icons/io";

const ProfileIcon = ({ imageUrl }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="profile-icon w-10 h-10 md:w-16 md:h-16 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 rounded-full flex justify-center items-center transition-all duration-300 ease-in-out hover:from-pink-500 hover:via-red-500 hover:to-yellow-400">
        <div className="inner-circle w-[93%] h-[93%] bg-gray-200 rounded-full flex justify-center items-center overflow-hidden">
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Profile"
              className="object-top object-cover w-full h-full transition-all duration-300 ease-in-out hover:brightness-110"
            />
          ) : (
            <IoMdPerson className="w-6 h-6" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileIcon;



// import React from "react";

// const ProfileIcon = ({ imageUrl }) => {
//   return (
//     <div className="flex justify-center items-center">
//       <div className="profile-icon w-10 h-10 md:w-16 md:h-16 bg-gradient-to-r from-white via-gray-600 to-gray-300 rounded-full flex justify-center items-center transition-all duration-300 ease-in-out hover:from-gray-600 hover:via-gray-300 hover:to-white">
//         <div className="inner-circle w-[90%] h-[90%] bg-gray-200 rounded-full flex justify-center items-center overflow-hidden">
//           {imageUrl ? (
//             <img
//               src={imageUrl}
//               alt="Profile"
//               className="object-top object-cover w-full h-full transition-all duration-300 ease-in-out hover:brightness-110"
//             />
//           ) : (
//             <div className="text-gray-400">No Image</div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfileIcon;
