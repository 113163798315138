import React, { useEffect, useState, useRef } from "react";
import "../components/pagination.css";
import FormatWidget from "../components/FormatWidget";
import XrTeamImages from "../components/XrTeamImages";
import { WEB_URL, XR_TEAM } from "../api/constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import ItemsPerPage from "../components/ItemsPerPage";
import ScrollTriggerModal from "../components/ScrollTriggerModal";
import LetXpertsInput from "../components/LetXpertsInput";

function XrTeam() {
  const [topSection, setTopSection] = useState();
  const [agents, setAgents] = useState([]);
  const [bottomSection, setBottomSection] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  const xrTeamRef = useRef(null); // Create a reference for the XrTeamImages section

  const fetchAgents = async (page, limit) => {
    try {
      // const response = await axios.get(`${XR_TEAM}`, {
      //   params: { page: currentPage, limit },
      // });

      const response = await axios.get(`${XR_TEAM}`);
      setTopSection(response?.data?.topSection);
      setAgents(response?.data?.agents);
      setBottomSection(response?.data?.bottomSection);
      // setTotalPages(response?.data?.totalPages);
      // setCurrentPage(response?.data?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (xrTeamRef.current) {
      xrTeamRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    // Update the URL to reflect the current page and limit
    // const params = new URLSearchParams(search);
    // params.set("page", currentPage); // Update the 'page' query param

    // const limit = parseInt(params.get("limit"), 10) || 8;
    // setItemsPerPage(limit);
    // navigate(`${pathname}?${params.toString()}`, { replace: true });

    fetchAgents(currentPage, itemsPerPage);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const handleLimitChange = (limit) => {
  //   setItemsPerPage(limit);
  //   setCurrentPage(1); // Reset to first page when limit changes
  // };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    // Update the limit and reset current page to 1
    const newUrl = `${pathname}?page=${1}&limit=${newLimit}`;
    window.location.href = newUrl; // Navigate and reload
  };

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "Xperience Realty | Real Estate Dubai",
            description:
              "Meet our experienced qualified team of the best Dubai real estate agents who cater to all your real estate needs",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: `${WEB_URL}${pathname.slice(1)}`,
          })}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.3]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}
      <div className="pt-16 pb-4 px-4 ">
        <FormatWidget
          title={topSection?.heading}
          subtitle={topSection?.description}
        >
          <div ref={xrTeamRef}>
            {/* <ItemsPerPage
              limit={itemsPerPage}
              onLimitChange={handleLimitChange}
              options={[8, 16, 24, 32, 64]}
            /> */}
          </div>
          <div>
            <XrTeamImages currentImages={agents} />
          </div>
        </FormatWidget>
        {/* <Pagination
          pagePath={pathname}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={itemsPerPage}
          setCurrentPage={setCurrentPage}
        /> */}
      </div>

      <FormatWidget
        subtitle={
          <div className="gap-4 flex flex-col">{bottomSection?.content}</div>
        }
      ></FormatWidget>
    </>
  );
}

export default XrTeam;
