export const BASE_URL = "https://api.xrealty.ae/";
// export const BASE_URL = "http://localhost:3333/";
export const FETCH_DEVELOPER = `${BASE_URL}label/`;
export const XR_TEAM = `${BASE_URL}meet-the-xr`;
export const XPLORE_DUBAI = `${BASE_URL}area/`;
export const AGENT = `${BASE_URL}agent/`;
export const STAR_AGENTS = `${BASE_URL}starAgents`;
export const NEWS_AND_INSIGHTS = `${BASE_URL}real-estate-news`;
export const PROPERTY = `${BASE_URL}property`;
export const CONTACT_US = `${BASE_URL}contact`;
export const CONTACT_US_CAREER = `${BASE_URL}career_contact`;
export const REVIEWS = `${BASE_URL}reviews`;
export const ALL_REVIEWS = `${BASE_URL}admin/getAllReviews`;
export const WEB_URL = "https://www.xrealty.ae/";
export const ALL_COMMUNITIES = `${BASE_URL}area/communities/`;
export const FETCH_All_DEVELOPERS = `${BASE_URL}admin/developers`;
export const FETCH_BLOGS = `${BASE_URL}blogs`;
export const FETCH_PROPERTY_TYPES = `${BASE_URL}propertyType`;
export const RESUME_UPLOAD = `${BASE_URL}resume/upload`;
