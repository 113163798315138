import React from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import FormatWidget from "./FormatWidget";
import FeatureCard from "./FeatureCard";

function LetXperts({ imagesWithInfo }) {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
  };

  const handleClick = () => {
    navigate(`${imagesWithInfo?.learnMore}`);
  };

  return (
    <div>
      <FormatWidget
        title="PROJECT OF THE MONTH"
        subtitle={imagesWithInfo?.projectName}
        abouttheProject={true}
      />

      <div className=" grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 3xl:grid-cols-1 4xl:grid-cols-2 4xl:gap-8 items-center bg-gray-100 pb-5 md:pb-5 lg:px-4 lg:pb-7 gap-5 lg:gap-2 overflow-hidden px-4  2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
        <div className="bg-white shadow-md col-span-1 aspect-video overflow-hidden ">
          <Slider {...settings}>
            <div className="aspect-video">
              <ReactPlayer
                width="100%"
                height="100%"
                url={imagesWithInfo?.videoUrl}
                playing
                volume={0}
                controls={true}
              />
            </div>
            {imagesWithInfo?.images?.map((image, index) => (
              <div
                key={index}
                className="flex items-center justify-center aspect-video  overflow-hidden"
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-cover overflow-hidden"
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="px-6 lg:pl-4 2xl:pl-0 h-full w-full flex flex-col justify-between 4xl:justify-around 4xl:gap-0 col-span-1 gap-2 3xl:gap-2">
          <h4 className="text-base md:text-lg lg:text-lg lg:mt-2 font-bold text-gray-900 ">
            About the Project
          </h4>
          <p className="text-sm md:text-sm font-light text-justify lg:text-sm 4xl:text-lg text-gray-700 leading-relaxed ">
            {imagesWithInfo?.description}
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-4 3xl:gap-8">
            {imagesWithInfo?.amenities?.icons?.map((feature, index) => (
              <FeatureCard
                key={index}
                iconUrl={feature?.icon_url}
                text={feature?.icon_text}
              />
            ))}
          </div>

          {/* Location details */}
          <div className="flex w-full  lg:mt-5 justify-between gap-4">
            <div className="flex flex-col items-center">
              <h2 className="text-base  md:text-lg lg:text-base 3xl:text-xl font-semibold text-gray-900 text-center">
                {imagesWithInfo?.headings[0].heading}
              </h2>
              <p className="text-gray-600 text-center text-xs  md:text-sm lg:text-sm 3xl:text-base">
                {imagesWithInfo?.headings[0].description}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h2 className="text-base  md:text-lg lg:text-base 3xl:text-xl font-semibold text-gray-900  text-center">
                {imagesWithInfo?.headings[1].heading}
              </h2>
              <p className="text-gray-600  text-center text-xs  md:text-sm lg:text-sm 3xl:text-base">
                {imagesWithInfo?.headings[1].description}
              </p>
            </div>
            <div className="flex flex-col items-center ">
              <h2 className="text-lg md:text-lg lg:text-base 3xl:text-xl font-semibold text-gray-900  text-center">
                {imagesWithInfo?.headings[2].heading}
              </h2>
              <p className="text-gray-600  text-center text-xs  md:text-sm lg:text-sm 3xl:text-base">
                {imagesWithInfo?.headings[2].description}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h2 className="text-base  md:text-lg lg:text-base 3xl:text-xl font-semibold text-gray-900 text-center">
                {imagesWithInfo?.headings[3].heading}
              </h2>
              <p className="text-gray-600 text-center  text-xs  md:text-sm lg:text-sm 3xl:text-base">
                {imagesWithInfo?.headings[3].description}
              </p>
            </div>
          </div>

          <button
            className="bg-black text-white py-1 px-10  md:py-1 md:mt-4 hover:bg-white hover:text-black hover:border-black border border-transparent transition-colors duration-300 text-xs  md:text-sm lg:text-sm 3xl:text-base"
            onClick={handleClick}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}

export default LetXperts;
