import React, { useEffect, useState } from "react";
import black_logo from "../assets/xr_logo_black.png";
import { Link, useLocation } from "react-router-dom";

function NewsAndInsightsImages({ imagesWithInfo, all }) {
  const [cardsPerView, setCardsPerView] = useState(4);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 428) {
        setCardsPerView(4);
      } else if (width <= 767) {
        setCardsPerView(4);
      } else if (width <= 884) {
        setCardsPerView(3);
      } else if (width > 884 && width <= 1023) {
        setCardsPerView(3);
      } else if (width <= 1024) {
        setCardsPerView(4);
      } else {
        setCardsPerView(4);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const allImagesWithInfo = all
    ? imagesWithInfo
    : imagesWithInfo?.slice(0, cardsPerView);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
  };

  return (
    <div className="grid grid-cols-2 pt-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-3">
      {allImagesWithInfo?.map((imageWithInfo, index) => (
        <div
          key={index}
          className="relative flex flex-col shadow-lg border border-gray-300 rounded-lg overflow-visible transition-transform hover:-translate-y-2 hover:shadow-2xl"
        >
          <div className="overflow-hidden rounded-t-lg">
            <Link to={`/${imageWithInfo?.slug}`} reloadDocument>
              <img
                alt={imageWithInfo?.title}
                src={imageWithInfo?.featured_image}
                className="aspect-video object-cover w-full transform hover:scale-105 transition-transform duration-1000"
              />
            </Link>
          </div>

          {/* Logo Clip */}
          <img
            alt="Clip"
            src={black_logo}
            className="absolute -top-3 right-2 h-6 w-6 md:-top-4 md:right-3 md:h-8 md:w-8 bg-white shadow-md rounded-sm border border-gray-300"
          />

          <div className="p-4">
            <dl>
              <div>
                <dt className="sr-only">Title</dt>
                <dd className="font-semibold text-sm md:text-base text-gray-800  line-clamp-1">
                  {imageWithInfo?.title}
                </dd>
              </div>
              <div className="mt-2">
                <dt className="sr-only">Date</dt>
                <dd className="text-xs md:text-sm text-gray-500">
                  {imageWithInfo?.publish_date !== null
                    ? formatDate(imageWithInfo?.publish_date)
                    : formatDate(Date.now())}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NewsAndInsightsImages;
