import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FETCH_DEVELOPER, WEB_URL } from "../api/constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Helmet from "react-helmet";
import ScrollTriggerModal from "../components/ScrollTriggerModal";
import LetXpertsInput from "../components/LetXpertsInput";
import ItemsPerPage from "../components/ItemsPerPage";
import Pagination from "../components/Pagination";
import black_logo from "../assets/xr_logo_black.png";
import Slider from "react-slick";
import "../components/StarEmployeesImages.css";

const projectPathNames = ["/label/omniyat/"];

function Developers() {
  const { id } = useParams();
  const [developer, setDeveloper] = useState();
  const [communities, setCommunities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [slideShowData, setSlideShowData] = useState([]);
  // const [itemsPerPage, setItemsPerPage] = useState(8); // Default items per page
  const [limit, setLimit] = useState(8);

  const communityRef = useRef(null);
  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  const sliderConfig = {
    // infinite:  true,
    speed: 3500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    swipe: true,
    pauseOnHover: false,
    arrows: true,
  };

  useEffect(() => {
    // if (communityRef.current) {
    //   communityRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //   });
    // }
    const fetchData = async () => {
      try {
        // const response = await axios.get(`${FETCH_DEVELOPER}${id}`, {
        //   params: { page: currentPage, limit: limit },
        // });
        const response = await axios.get(`${FETCH_DEVELOPER}${id}`);
        setDeveloper(response?.data?.aboutDeveloper);
        setCommunities(response?.data?.developerCommunities);
        setTotalPages(response?.data?.totalPages);
        setSlideShowData(response?.data?.slideShowData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Update the URL to reflect the current page and limit
    // const params = new URLSearchParams(search);
    // params.set("page", currentPage); // Update the 'page' query param

    // const pageLimit = parseInt(params.get("limit"), 10) || 8;
    // setLimit(pageLimit);
    // navigate(`${pathname}?${params.toString()}`, { replace: true });
  }, [id, currentPage, limit]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handleLimitChange = (newLimit) => {
  //   setLimit(newLimit);
  //   // setItemsPerPage(newLimit);
  //   setCurrentPage(1); // Reset to the first page when limit changes
  // };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    // Update the limit and reset current page to 1
    const newUrl = `${pathname}?page=${1}&limit=${newLimit}`;
    window.location.href = newUrl; // Navigate and reload
  };

  return (
    <div>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>
          {developer?.developer_name || "Xperience Realty | Real Estate Dubai"}
        </title> */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Website",
            name:
              developer?.developer_name ||
              "Xperience Realty | Real Estate Dubai",
            description:
              developer?.description || "Developer details and properties",
            image: developer?.logo_img_url,
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.3]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}

      {slideShowData?.length > 0 && (
        <Slider
          className="overflow-hidden"
          {...sliderConfig}
          infinite={slideShowData.length > 1}
        >
          {slideShowData?.map((slide) => {
            return (
              <div className="h-[92vh] w-full">
                <div className="relative min-h-screen flex flex-col">
                  <div
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${slide?.backgroundUrl})`,
                    }}
                  >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                  </div>

                  <div className="relative z-10 flex-grow flex flex-col justify-center items-center text-center text-white px-[13%] 4xl:gap-4 md:pl-[8%] lg:px-[6%] 4xl:px-[12%]">
                    <div className="inline-block font-extralight px-4 py-1 bg-transparent border border-white text-xs  4xl:text-sm mb-4 self-start">
                      NEWLY LAUNCHED
                    </div>
                    <h1 className="text-lg text-left md:text-2xl 4xl:text-5xl mb-2 tracking-wider font-thin line-clamp-1 self-start ">
                      {slide?.property_name?.toUpperCase()} -{" "}
                      {slide?.developer_name?.toUpperCase()}
                    </h1>
                    <p className="text-sm md:text-lg 4xl:text-3xl  mb-6 tracking-wider font-light self-start line-clamp-1  text-left">
                      {slide.slogan}
                    </p>
                    <Link
                      reloadDocument
                      to={`${slide?.learnMore}/`}
                      className="text-sm px-3 py-1 font-normal md:px-2 md:py-1 4xl:px-2 4xl:py-2 bg-white text-gray-800 border border-black  hover:bg-gray-200 transition duration-300 self-start"
                    >
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      )}

      <div className="lg:col-span-6 pt-16 pb-4 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] ">
        <div className="flex flex-col gap-8 ">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <div>
              <div className="flex flex-row items-center gap-4">
                <img
                  src={developer?.logo_img_url}
                  alt=""
                  className="w-[9rem] h-[5rem]"
                />
                <h1 className="text-xl font-normal">
                  {developer?.developer_name}
                </h1>
              </div>
              <p className="font-light mt-4">{developer?.description}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col gap-10 lg:col-span-6  md:py-3 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] py-8 lg:py-16"
        ref={communityRef}
      >
        <div className="flex justify-between items-center ">
          <h1 className="text-3xl tracking-wide">
            {projectPathNames.includes(pathname) ? "Projects" : "Communities"}
          </h1>
          {/* <ItemsPerPage
            limit={limit}
            onLimitChange={handleLimitChange}
            options={[8, 16, 24, 32, 64]}
          /> */}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {communities?.map((imageWithInfo, index) => (
            // <div
            //   key={index}
            //   className="relative h-auto flex flex-col  border shadow-md overflow-visible"
            // >
            //   <Link reloadDocument to={`/area/${imageWithInfo.slug}/`}>
            //     <img
            //       alt=""
            //       src={imageWithInfo?.images[0]?.url}
            //       className="aspect-video w-full object-cover"
            //     />
            //   </Link>

            //   <img
            //     alt="Clip"
            //     src={black_logo}
            //     className="absolute -top-4 right-4 h-9 w-9 bg-white"
            //   />
            //   <div className="p-4 overflow-hidden flex justify-center items-center h-full">
            //     <dl>
            //       <div>
            //         <dt className="sr-only">Title</dt>
            //         <dd className="text-sm md:text-lg  text-black  text-center">
            //           {imageWithInfo?.name?.toUpperCase()}
            //         </dd>
            //       </div>
            //     </dl>
            //   </div>
            // </div>

            <div
              key={index}
              className="relative flex-none pt-4 transition-transform hover:-translate-y-2 hover:shadow-2xl rounded-lg"
            >
              <div className="relative border border-gray-300 rounded-lg shadow-lg overflow-visible">
                <div className="overflow-hidden rounded-t-lg">
                  <Link reloadDocument to={`/area/${imageWithInfo?.slug}/`}>
                    <img
                      alt={imageWithInfo?.name}
                      src={imageWithInfo?.images[0]?.url}
                      className="aspect-video w-full object-cover transform hover:scale-105 transition-transform duration-1000"
                    />
                  </Link>
                </div>

                {/* Logo Clip */}
                <img
                  alt="Clip"
                  src={black_logo}
                  className="absolute -top-3 right-2 h-6 w-6 md:-top-4 md:right-3 md:h-8 md:w-8 bg-white shadow-md rounded-sm border border-gray-300"
                />

                <div className="py-3 px-4 text-center">
                  <dl>
                    <div>
                      <dt className="sr-only">Title</dt>
                      <dd className="text-sm md:text-base text-gray-800 font-semibold truncate">
                        {imageWithInfo?.name.toUpperCase()}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <Pagination
          pagePath={pathname}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          setCurrentPage={setCurrentPage}
        /> */}
      </div>
    </div>
  );
}

export default Developers;
