import React from "react";

const FeatureCard = ({ iconUrl, text }) => {
  return (
    <div className="flex flex-col items-center justify-top gap-3 lg:gap-1 2xl:gap-3  p-6 lg:p-2 2xl:p-6  border border-gray-300 transition-transform duration-300 hover:scale-105 hover:shadow-md">
      <img
        className="w-5 h-5 lg:h-6 md:w-8 md:h-8 lg:w-6 2xl:w-8 2xl:h-8 text-gray-700"
        src={iconUrl}
        alt=""
      />
      <p className="text-center text-sm md:text-sm lg:text-sm 2xl:text-base font-medium text-gray-700">
        {text}
      </p>
    </div>
  );
};

export default FeatureCard;
