// import React, { useEffect, useState } from "react";
// import { FaBed } from "react-icons/fa";
// import { HiLocationMarker } from "react-icons/hi";
// import black_logo from "../assets/xr_logo_black.png";
// import axios from "axios";
// import { XPLORE_DUBAI } from "../api/constants";
// import { Link, useParams } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { IoMdPerson } from "react-icons/io";
// import ScrollTriggerModal from "./ScrollTriggerModal";
// import LetXpertsInput from "./LetXpertsInput";

// function OurCommunities() {
//   const { title } = useParams();

//   const [community, setCommunity] = useState();
//   const [moreCommunities, setMoreCommunities] = useState();
//   const [properties, setProperties] = useState();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(XPLORE_DUBAI + `${title}`);
//         setCommunity(response?.data?.community);
//         setMoreCommunities(response?.data?.moreCommunities);
//         setProperties(response?.data?.properties);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, [title]);
//   const icons = [<FaBed />, <IoMdPerson />, <HiLocationMarker />];
//   return (
//     <div className="flex flex-col gap-14 mt-8 mb-8">
//       <Helmet>
//         {/* <title>
//           {community?.seo?.meta_title +
//             "- Xperience Realty | Real Estate Dubai"}
//         </title> */}
//         <script type="application/ld+json">
//           {JSON.stringify(community?.schema_org?.properties)}
//         </script>
//       </Helmet>
//       <ScrollTriggerModal triggerPoints={[0.3, 0.6]}>
//         <LetXpertsInput theme="light" />
//       </ScrollTriggerModal>

//       {community?.images?.length > 0 && (
//         <div className="  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
//           <div className="flex flex-col">
//             <div className="flex flex-col md:flex-row  md:items-center justify-between mb-4 ">
//               <div className="flex flex-col mr-2 gap-2 lg:gap-4">
//                 <h2 className="font-bold sm:text-sm  md:text-2xl ">
//                   {community?.name}
//                 </h2>
//                 <h3 className="text-sm md:text-base font-extralight leading-6 text-gray-500">
//                   {community?.description}
//                 </h3>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {community?.images?.length > 0 && (
//         <img
//           src={community?.images[0]?.url}
//           alt=""
//           className="w-full lg:h-[80dvh] object-cover"
//         />
//       )}

//       <div className="flex flex-col gap-20">
//         {community?.amenities?.icons?.length > 0 && (
//           <div className=" px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-x-40 gap-y-4 flex-wrap">
//             <h2 className="font-bold sm:text-sm  md:text-2xl ">
//               COMMUNITY FEATURES
//             </h2>
//             <h3 className="text-sm md:text-base font-extralight leading-6 text-gray-500 mb-6">
//               {community?.amenities?.description}
//             </h3>
//             <div className=" grid grid-cols-2 md:grid-cols-4 gap-y-4 ">
//               {community?.amenities?.icons?.map((icon, index) => (
//                 <div
//                   key={index + "-" + index}
//                   className="flex flex-col items-center gap-1"
//                 >
//                   {icon.icon_url && (
//                     <img
//                       className="w-8 h-8"
//                       src={icon.icon_url}
//                       alt={icon.icon_text}
//                     />
//                   )}
//                   {icon.icon_text && (
//                     <p className="text-sm md:text-base">
//                       {icon.icon_text}
//                     </p>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}
//         {properties?.length > 0 && (
//           <div className=" px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-x-40 gap-y-10 flex-wrap ">
//             <h2 className="font-bold sm:text-sm  md:text-2xl ">PROPERTIES</h2>
//             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
//               {properties?.map((property, index) => (
//                 <div
//                   key={index}
//                   className="relative h-auto flex flex-col justify-between border border-gray-300 overflow-visible"
//                 >
//                   <div className="overflow-hidden">
//                     <Link
//                       to={`/property/${property?.property_name_slug}`}
//                       reloadDocument
//                     >
//                       <img
//                         alt=""
//                         src={property?.images[0]?.url}
//                         className="aspect-video w-full object-cover lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
//                       />
//                     </Link>
//                   </div>

//                   <img
//                     alt="Clip"
//                     src={black_logo}
//                     className="absolute -top-4 right-4 h-9 w-9 bg-white"
//                   />
//                   <div className="p-4 overflow-hidden">
//                     <dl>
//                       <div>
//                         <dt className="sr-only">Title</dt>
//                         <dd className="sm:text-sm  md:text-xl  text-black font-semibold">
//                           {property?.property_name.toUpperCase()}
//                         </dd>
//                       </div>
//                       <div>
//                         <dd className="text-sm md:text-sm  font-light text-gray-700">
//                           STARTING FROM {property?.price}
//                         </dd>
//                       </div>
//                     </dl>

//                     <div className="md:mt-2 lg:mt-3 items-center lg:gap-2 gap-4 sm:gap-1">
//                       <div className="sm:shrink-0 sm:items-center sm:gap-x-2   md:text-base lg:text-xl  text-gray-500  flex-wrap flex gap-1">
//                         <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs ">
//                           <div>{icons[0]}</div>
//                           <p className="font-light ">
//                             {property?.type[0]?.bedrooms}
//                           </p>
//                         </div>
//                         <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs ">
//                           <div>{icons[1]}</div>
//                           <p className="font-light">{property?.developer}</p>
//                         </div>
//                         <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs ">
//                           <div>{icons[2]}</div>
//                           <p className="font-light">
//                             {property?.community_name?.toLowerCase()}
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}
//       </div>

//       <div className=" px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-x-40 gap-y-10 flex-wrap mb-20">
//         <h2 className="font-bold sm:text-sm  md:text-2xl ">MORE COMMUNITIES</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
//           {moreCommunities?.map((community, index) => (
//             <div
//               key={index}
//               className="relative h-auto flex flex-col border  border-gray-300 overflow-visible"
//             >
//               <div className="overflow-hidden">
//                 <Link to={`/area/${community.slug}`} reloadDocument>
//                   <img
//                     alt=""
//                     src={community?.images[0]?.url}
//                     className="aspect-video w-full object-cover lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
//                   />
//                 </Link>
//               </div>

//               <img
//                 alt="xr-logo-clip"
//                 src={black_logo}
//                 className="absolute -top-4 right-4 h-9 w-9 bg-white"
//               />
//               <div className="p-4 overflow-hidden">
//                 <dl>
//                   <div>
//                     <dt className="sr-only">Title</dt>
//                     <dd className="text-lg md:text-xl  text-black font-semibold text-center">
//                       {community?.name}
//                     </dd>
//                   </div>
//                 </dl>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OurCommunities;

import React, { useEffect, useState } from "react";
import { FaBed } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import black_logo from "../assets/xr_logo_black.png";
import axios from "axios";
import { XPLORE_DUBAI } from "../api/constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IoMdPerson } from "react-icons/io";
import ScrollTriggerModal from "./ScrollTriggerModal";
import LetXpertsInput from "./LetXpertsInput";
import ItemsPerPage from "./ItemsPerPage";
import Pagination from "./Pagination";

function OurCommunities() {
  const { title } = useParams();

  const [community, setCommunity] = useState();
  const [moreCommunities, setMoreCommunities] = useState([]);
  const [properties, setProperties] = useState([]);

  // State for properties pagination
  const [currentPropertiesPage, setCurrentPropertiesPage] = useState(1);
  const [propertiesPerPage, setPropertiesPerPage] = useState(8);
  const [totalPropertiesPages, setTotalPropertiesPages] = useState(1);

  // State for more communities pagination
  const [currentMoreCommunitiesPage, setCurrentMoreCommunitiesPage] =
    useState(1);
  const [moreCommunitiesPerPage, setMoreCommunitiesPerPage] = useState(8);
  const [totalMoreCommunitiesPages, setTotalMoreCommunitiesPages] = useState(1);

  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    // Update the URL to reflect the current page and limit
    // const params = new URLSearchParams(search);
    // params.set("page", currentPropertiesPage); // Update the 'page' query param

    // const pageLimit = parseInt(params.get("limit"), 10) || 8;
    // setPropertiesPerPage(pageLimit);
    // navigate(`${pathname}?${params.toString()}`, { replace: true });
    fetchData();
  }, [
    title,
    currentPropertiesPage,
    propertiesPerPage,
    currentMoreCommunitiesPage,
    moreCommunitiesPerPage,
  ]);

  const fetchData = async () => {
    try {
      // Old one
      // const response = await axios.get(XPLORE_DUBAI + `${title}`, {
      //   params: {
      //     propertiesPage: currentPropertiesPage,
      //     propertiesLimit: propertiesPerPage,
      //     moreCommunitiesPage: currentMoreCommunitiesPage,
      //     moreCommunitiesLimit: moreCommunitiesPerPage,
      //   },
      // });
      const response = await axios.get(XPLORE_DUBAI + `${title}`);
      setCommunity(response?.data?.community);
      setProperties(response?.data?.properties);
      setTotalPropertiesPages(response?.data?.totalPropertiesPages);
      setMoreCommunities(response?.data?.moreCommunities);
      setTotalMoreCommunitiesPages(response?.data?.totalMoreCommunitiesPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handlers for properties pagination
  const handlePropertiesPageChange = (newPage) => {
    setCurrentPropertiesPage(newPage);
  };

  // const handlePropertiesLimitChange = (newLimit) => {
  //   setPropertiesPerPage(newLimit);
  //   setCurrentPropertiesPage(1); // Reset to the first page when limit changes
  // };

  const handlePropertiesLimitChange = (newLimit) => {
    // console.log(newLimit);
    // Update the limit and reset current page to 1
    const newUrl = `${pathname}?page=${1}&limit=${newLimit}`;
    window.location.href = newUrl; // Navigate and reload
  };

  // Handlers for more communities pagination
  const handleMoreCommunitiesPageChange = (newPage) => {
    setCurrentMoreCommunitiesPage(newPage);
  };

  const handleMoreCommunitiesLimitChange = (newLimit) => {
    setMoreCommunitiesPerPage(newLimit);
    setCurrentMoreCommunitiesPage(1); // Reset to the first page when limit changes
  };

  const icons = [<FaBed />, <IoMdPerson />, <HiLocationMarker />];
  return (
    <div className="flex flex-col gap-14 mt-8 mb-8">
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>
          {community?.seo?.meta_title +
            "- Xperience Realty | Real Estate Dubai"}
        </title> */}
        <script type="application/ld+json">
          {JSON.stringify(community?.schema_org?.properties)}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.3, 0.6]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}

      {community?.images?.length > 0 && (
        <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row md:items-center justify-between mb-4 ">
              <div className="flex flex-col mr-2 gap-2 lg:gap-4">
                <h1 className="font-medium sm:text-sm md:text-2xl tracking-wider">
                  {community?.name}
                </h1>
                <h3 className="text-sm md:text-base font-extralight leading-6 text-gray-500">
                  {community?.description}
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}

      {community?.images?.length > 0 && (
        <img
          src={community?.images[0]?.url}
          alt=""
          className="w-full lg:h-[80dvh] object-cover"
        />
      )}

      <div className="flex flex-col gap-20">
        {community?.amenities?.icons?.length > 0 && (
          <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-x-40 gap-y-4 flex-wrap">
            <h1 className="sm:text-sm md:text-2xl tracking-wider">
              COMMUNITY FEATURES
            </h1>
            <h3 className="text-sm md:text-base font-extralight leading-6 text-gray-500 mb-6">
              {community?.amenities?.description}
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-y-4">
              {community?.amenities?.icons?.map((icon, index) => (
                <div
                  key={index + "-" + index}
                  className="flex flex-col items-center gap-1"
                >
                  {icon.icon_url && (
                    <img
                      className="w-8 h-8"
                      src={icon.icon_url}
                      alt={icon.icon_text}
                    />
                  )}
                  {icon.icon_text && (
                    <p className="text-sm md:text-base">{icon.icon_text}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {properties?.length > 0 && (
          <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-x-40 gap-y-10 flex-wrap">
            <h1 className="sm:text-sm md:text-2xl tracking-wider">
              MORE PROPERTIES
            </h1>
            {totalPropertiesPages !== null && (
              <ItemsPerPage
                limit={propertiesPerPage}
                onLimitChange={handlePropertiesLimitChange}
                options={[8, 16, 24, 48, 72, 100]}
              />
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              {properties.map((property, index) => (
                // <div
                //   key={index}
                //   className="relative h-auto flex flex-col justify-between border border-gray-300 overflow-visible"
                // >
                //   <div className="overflow-hidden">
                //     <Link
                //       to={`/property/${property?.property_name_slug}/`}
                //       reloadDocument
                //     >
                //       <img
                //         alt=""
                //         src={property?.images[0]?.url}
                //         className="aspect-video w-full object-cover lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
                //       />
                //     </Link>
                //   </div>

                //   <img
                //     alt="Clip"
                //     src={black_logo}
                //     className="absolute -top-4 right-4 h-9 w-9 bg-white"
                //   />
                //   <div className="p-4 overflow-hidden">
                //     <dl>
                //       <div>
                //         <dt className="sr-only">Title</dt>
                //         <dd className="sm:text-sm md:text-xl text-black">
                //           {property?.property_name.toUpperCase()}
                //         </dd>
                //       </div>
                //       <div>
                //         <dd className="text-sm md:text-sm font-light text-gray-700">
                //           STARTING FROM {property?.price}
                //         </dd>
                //       </div>
                //     </dl>

                //     <div className="md:mt-2 lg:mt-3 items-center lg:gap-2 gap-4 sm:gap-1">
                //       <div className="sm:shrink-0 sm:items-center sm:gap-x-2 md:text-base lg:text-xl text-gray-500 flex-wrap flex gap-1">
                //         <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs ">
                //           <div>{icons[0]}</div>
                //           <p className="font-light ">
                //             {property?.type[0]?.bedrooms}
                //           </p>
                //         </div>
                //         <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs ">
                //           <div>{icons[1]}</div>
                //           <p className="font-light">{property?.developer}</p>
                //         </div>
                //         <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs ">
                //           <div>{icons[2]}</div>
                //           <p className="font-light">
                //             {property?.community_name?.toLowerCase()}
                //           </p>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>

                <div
                  key={index}
                  className="relative flex-none pt-4 transition-transform hover:-translate-y-2 hover:shadow-2xl rounded-lg"
                >
                  <div className="relative border border-gray-300 rounded-lg shadow-lg overflow-visible">
                    <div className="overflow-hidden rounded-t-lg">
                      <Link
                        reloadDocument
                        to={`/property/${property?.property_name_slug}/`}
                      >
                        <img
                          alt={property?.property_name}
                          src={property?.images[0]?.url}
                          className="aspect-video w-full object-cover transform hover:scale-105 transition-transform duration-1000"
                        />
                      </Link>
                    </div>

                    {/* Logo Clip */}
                    <img
                      alt="Clip"
                      src={black_logo}
                      className="absolute -top-3 right-2 h-6 w-6 md:-top-4 md:right-3 md:h-8 md:w-8 bg-white shadow-md rounded-sm border border-gray-300"
                    />

                    <div className="py-3 px-4 text-center">
                      <dl>
                        <div>
                          <dt className="sr-only">Title</dt>
                          <dd className="text-sm md:text-base text-gray-800 font-semibold truncate">
                            {property?.property_name.toUpperCase()}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {totalPropertiesPages !== null && (
              <Pagination
                pagePath={pathname}
                currentPage={currentPropertiesPage}
                totalPages={totalPropertiesPages}
                limit={propertiesPerPage}
                setCurrentPage={setCurrentPropertiesPage}
              />
            )}
          </div>
        )}
      </div>

      {/* <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-x-40 gap-y-10 flex-wrap mb-20">
        <h2 className="sm:text-sm md:text-2xl tracking-wider">
          MORE COMMUNITIES
        </h2>
        <ItemsPerPage
          limit={moreCommunitiesPerPage}
          onLimitChange={handleMoreCommunitiesLimitChange}
          options={[8, 16, 24, 48, 72, 100]}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {moreCommunities.map((community, index) => (
            <div
              key={index}
              className="relative h-auto flex flex-col border border-gray-300 overflow-visible"
            >
              <div className="overflow-hidden">
                <Link to={`/area/${community.slug}`} reloadDocument>
                  <img
                    alt=""
                    src={community?.images[0]?.url}
                    className="aspect-video w-full object-cover lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
                  />
                </Link>
              </div>

              <img
                alt="xr-logo-clip"
                src={black_logo}
                className="absolute -top-4 right-4 h-9 w-9 bg-white"
              />
              <div className="p-4 overflow-hidden">
                <dl>
                  <div>
                    <dt className="sr-only">Title</dt>
                    <dd className="text-lg md:text-xl text-black  text-center">
                      {community?.name}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentMoreCommunitiesPage}
          totalPages={totalMoreCommunitiesPages}
          onPageChange={handleMoreCommunitiesPageChange}
        />
      </div> */}
    </div>
  );
}

export default OurCommunities;
