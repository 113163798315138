import { FaArrowCircleLeft, FaArrowCircleRight, FaBed } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import black_logo from "../assets/xr_logo_black.png";
import { HiLocationMarker } from "react-icons/hi";
import { IoMdPerson } from "react-icons/io";

function ProjectImages({ imagesWithInfo, viewAll, newsImages }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsPerView, setCardsPerView] = useState(4);
  const icons = [<FaBed />, <IoMdPerson />, <HiLocationMarker />];

  let UpdatedImagesWithInfo = imagesWithInfo;
  if (newsImages) {
    UpdatedImagesWithInfo = imagesWithInfo;
  }

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 428) {
        setCardsPerView(1);
      } else if (width <= 768) {
        setCardsPerView(2);
      } else if (width <= 1024) {
        setCardsPerView(3);
      } else {
        setCardsPerView(4);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    const maxIndex = Math.max(0, UpdatedImagesWithInfo?.length - cardsPerView);
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // const Card = ({ imageWithInfo }) => (
  //   <div
  //     className="relative h-auto flex-none pt-4 4xl:h-auto"
  //     style={{
  //       width: `calc((100% - ${(cardsPerView - 1) * 32}px) / ${cardsPerView})`,
  //     }}
  //   >
  //     <div className="relative h-full border border-gray-300">
  //       <div className="overflow-hidden">
  //         <Link
  //           reloadDocument
  //           to={`/property/${
  //             newsImages
  //               ? imageWithInfo?.slug
  //               : imageWithInfo?.property_name_slug
  //           }/`}
  //         >
  //           <img
  //             alt=""
  //             src={imageWithInfo?.images[0]?.url}
  //             className="aspect-video w-full object-cover md:object-fill lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
  //           />
  //         </Link>
  //       </div>

  //       <img
  //         alt="Clip"
  //         src={black_logo}
  //         className="absolute -top-2 right-2 h-5 w-5 md:-top-3 md:right-3 md:h-7 md:w-7 bg-white shadow-sm"
  //       />

  //       <div className="p-4 overflow-hidden">
  //         <dl>
  //           <div>
  //             <dt className="sr-only">Title</dt>
  //             <dd className="text-xs md:text-sm text-black font-bold">
  //               {newsImages
  //                 ? imageWithInfo?.title
  //                 : imageWithInfo?.property_name.toUpperCase()}
  //             </dd>
  //           </div>
  //           <div>
  //             <dd className="text-xs md:text-xs font-light text-gray-700">
  //               {newsImages
  //                 ? imageWithInfo?.images[0]?.description
  //                 : `STARTING FROM ${imageWithInfo?.price}`}
  //             </dd>
  //           </div>
  //         </dl>

  //         <div className="md:mt-2 lg:mt-3 items-center lg:gap-2 gap-4 sm:gap-1">
  //           <div className="sm:shrink-0 sm:items-center sm:gap-x-2 md:text-xs lg:text-xl text-gray-500 flex-wrap flex gap-1">
  //             {imageWithInfo?.type[0]?.bedrooms && (
  //               <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
  //                 <div>{icons[0]}</div>
  //                 <p className="font-light ">
  //                   {imageWithInfo?.type[0]?.bedrooms}
  //                 </p>
  //               </div>
  //             )}
  //             {imageWithInfo?.developer && (
  //               <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
  //                 <div>{icons[1]}</div>
  //                 <p className="font-light">
  //                   {imageWithInfo?.developer.toUpperCase()}
  //                 </p>
  //               </div>
  //             )}
  //             {imageWithInfo?.community_name && (
  //               <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-xs">
  //                 <div>{icons[2]}</div>
  //                 <p className="font-light">
  //                   {imageWithInfo?.community_name?.toUpperCase()}
  //                 </p>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const Card = ({ imageWithInfo }) => (
    <div
      className="relative h-auto flex-none pt-4 transition-transform hover:-translate-y-2 hover:shadow-2xl rounded-lg"
      style={{
        width: `calc((100% - ${(cardsPerView - 1) * 32}px) / ${cardsPerView})`,
      }}
    >
      <div className="relative h-full border border-gray-300 rounded-lg overflow-visible shadow-lg">
        <div className="overflow-hidden rounded-t-lg">
          <Link
            reloadDocument
            to={`/property/${
              newsImages
                ? imageWithInfo?.slug
                : imageWithInfo?.property_name_slug
            }/`}
          >
            <img
              alt={
                newsImages ? imageWithInfo?.title : imageWithInfo?.property_name
              }
              src={imageWithInfo?.images[0]?.url}
              className="aspect-video w-full object-cover md:object-fill transform hover:scale-105 transition-transform duration-1000"
            />
          </Link>
        </div>

        {/* Logo Clip */}
        {/* <img
          alt="Clip"
          src={black_logo}
          className="absolute -top-4 right-2 h-8 w-8 md:-top-3 md:right-3 md:h-10 md:w-10 bg-white shadow-md rounded-sm border border-gray-300"
        /> */}

        <img
          alt="Clip"
          src={black_logo}
          className="absolute -top-3 right-2 h-6 w-6 md:-top-4 md:right-3 md:h-8 md:w-8 bg-white shadow-md rounded-sm border border-gray-300"
        />

        <div className="p-4">
          <dl>
            <div>
              <dt className="sr-only">Title</dt>
              <dd className="text-sm md:text-base font-bold text-gray-800 truncate">
                {newsImages
                  ? imageWithInfo?.title
                  : imageWithInfo?.property_name.toUpperCase()}
              </dd>
            </div>
            <div>
              <dd className="text-xs md:text-sm font-light text-gray-600 truncate">
                {newsImages
                  ? imageWithInfo?.images[0]?.description
                  : `STARTING FROM ${imageWithInfo?.price}`}
              </dd>
            </div>
          </dl>

          <div className="flex items-center mt-3 space-x-4 text-gray-500 flex-wrap">
            {imageWithInfo?.type[0]?.bedrooms && (
              <div className="flex items-center gap-1 text-xs md:text-sm">
                <div>{icons[0]}</div>
                <p className="font-light">
                  {imageWithInfo?.type[2]?.bedrooms
                    ? imageWithInfo?.type[2]?.bedrooms
                    : imageWithInfo?.type[0]?.bedrooms}
                </p>
              </div>
            )}
            {imageWithInfo?.developer && (
              <div className="flex items-center gap-1 text-xs md:text-sm">
                <div>{icons[1]}</div>
                <p className="font-light">
                  {imageWithInfo?.developer.toUpperCase()}
                </p>
              </div>
            )}
            {imageWithInfo?.community_name && (
              <div className="flex items-center gap-1 text-xs md:text-sm">
                <div>{icons[2]}</div>
                <p className="font-light">
                  {imageWithInfo?.community_name.toUpperCase()}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="relative">
      {/* Navigation Arrows */}
      {UpdatedImagesWithInfo?.length > cardsPerView && (
        <>
          {/* <button
            onClick={scrollLeft}
            className={`absolute -left-1 top-1/2 -translate-y-1/2 z-20 text-black ${
              currentIndex === 0
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-gray-700 cursor-pointer"
            }`}
            disabled={currentIndex === 0}
          >
            <FaArrowCircleLeft size={32} />
          </button>
          <button
            onClick={scrollRight}
            className={`absolute -right-1 top-1/2 -translate-y-1/2 z-20 text-black ${
              currentIndex >= UpdatedImagesWithInfo.length - cardsPerView
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-gray-700 cursor-pointer"
            }`}
            disabled={
              currentIndex >= UpdatedImagesWithInfo.length - cardsPerView
            }
          >
            <FaArrowCircleRight size={32} />
          </button> */}
          <button
            onClick={scrollLeft}
            className={`absolute -left-1 top-1/2 -translate-y-1/2 z-20 text-black `}
            disabled={currentIndex === 0}
          >
            <FaArrowCircleLeft size={32} />
          </button>
          <button
            onClick={scrollRight}
            className={`absolute -right-1 top-1/2 -translate-y-1/2 z-20 text-black `}
            disabled={
              currentIndex >= UpdatedImagesWithInfo.length - cardsPerView
            }
          >
            <FaArrowCircleRight size={32} />
          </button>
        </>
      )}

      {/* Cards Container */}
      <div className="mx-8 overflow-hidden ">
        <div
          className="flex gap-8 transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateX(calc(-${currentIndex} * (100% + 32px) / ${cardsPerView}))`,
          }}
        >
          {UpdatedImagesWithInfo?.map((imageWithInfo, index) => (
            <Card key={index} imageWithInfo={imageWithInfo} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectImages;
