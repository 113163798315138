import React from "react";
import ProjectImages from "./ProjectImages";
import FormatWidget from "./FormatWidget";

function Projects({ imagesWithInfo }) {
  return (
    <>
      <FormatWidget
        title="XPERIENCE NEW PROJECTS"
        subtitle="Off-Plan Properties"
        linkText="VIEW ALL"
        linkTo="/dubai-properties/"
      >
        <ProjectImages imagesWithInfo={imagesWithInfo} />
      </FormatWidget>
    </>
  );
}

export default Projects;
