import React from "react";
import FormatWidget from "./FormatWidget";

export default function AboutXR() {
  return (
    <FormatWidget title="ABOUT XPERIENCE REALTY">
      <p className="text-justify leading-relaxed font-light text-sm md:text-sm lg:text-sm 3xl:text-lg">
        Xperience Realty is where the concept of excellence in Dubai real estate
        consultation reaches new heights. Beyond being a mere company, we
        proudly identify ourselves as a university of knowledge and innovation
        in the world of real estate. Just as our name implies, we stand as a
        shining example of distinction within the elite world of high-profile
        real estate guidance. Building upon a foundation of rich experience and
        an irreplaceable commitment to excellence, we have birthed XR as a brand
        synonymous with quality.
      </p>
    </FormatWidget>
  );
}
