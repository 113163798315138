// import React from "react";
// import { Link } from "react-router-dom";
// import Slider from "react-slick";

// function XploreDubaiImages({ sections, projectImages, aboutUs }) {
//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: false,
//     autoplaySpeed: 3000,
//   };
//   return (
//     <div className="w-full">
//       {sections?.map((section, index) => (
//         <section
//           key={index}
//           className="lg:mb-12 h-auto"
//           initial="hidden"
//           animate="visible"
//           // variants={containerVariants}
//         >
//           <div className="mx-auto pb-8  lg:m-0 lg:p-0 ">
//             <div
//               className={`grid grid-cols-1 gap-8 lg:grid-cols-2 items-center  ${
//                 index % 2 === 0 ? "" : "lg:order-last"
//               }`}
//             >
//               <div
//                 className={`relative overflow-hidden h-auto lg:col-span-1 ${
//                   index % 2 === 0 ? "lg:order-first" : "lg:order-last"
//                 }`}
//                 // variants={itemVariants}
//               >
//                 <img
//                   alt={
//                     projectImages
//                       ? section?.heading?.toUpperCase()
//                       : section?.tagLine?.toUpperCase()
//                   }
//                   src={projectImages ? section?.url : section?.imageUrl}
//                   className="aspect-auto object-cover"
//                 />
//               </div>

//               <div
//                 className="lg:col-span-1 flex flex-col gap-2"
//                 // variants={textVariants}
//               >
//                 {projectImages && (
//                   <span className="flex items-center">
//                     <span className="pr-6 text-gray-400 text-sm">
//                       {projectImages
//                         ? section?.heading?.toUpperCase()
//                         : section?.tagLine?.toUpperCase()}
//                     </span>
//                     <span className="h-px w-32 bg-gray-300"></span>
//                   </span>
//                 )}

//                 <h3 className="text-xs  md:text-sm text-gray-700">
//                   {section?.subtitle?.toUpperCase()}
//                 </h3>
//                 <h2 className="font-bold sm:text-sm  md:text-2xl">
//                   {section?.title?.toUpperCase()}

//                 </h2>
//                 <p className="text-sm md:text-base font-extralight leading-6 md:leading-7 text-gray-500">
//                   {section?.description}
//                 </p>
//                 <ul className="text-sm md:text-base  font-extralight text-gray-600 flex flex-col gap-2">
//                   {section?.features?.map((feature, idx) => (
//                     <div key={idx} className="flex gap-2">
//                       <img
//                         className="w-5 mr-1"
//                         src="https://cdn.worldvectorlogo.com/logos/dockers-4.svg"
//                         alt=""
//                       />
//                       <li>{feature}</li>
//                     </div>
//                   ))}
//                 </ul>
//                 {!aboutUs && !projectImages && (
//                   <Link
//                     reloadDocument
//                     to={`/area/${section.slug}`}
//                     className="mt-3 text-sm md:text-base  p-1 md:w-36  flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white"
//                   >
//                     FIND PROPERTY
//                   </Link>
//                 )}
//               </div>
//             </div>
//           </div>
//         </section>
//       ))}
//     </div>
//   );
// }

// export default XploreDubaiImages;

import React from "react";
import { FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function XploreDubaiImages({ sections, projectImages, aboutUs }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  return (
    <div className="w-full ">
      {sections?.map((section, index) => (
        <section key={index} className="flex flex-col h-auto ">
          <div className="my-6 lg:my-10 lg:p-0">
            <div
              className={`grid grid-cols-1 gap-4 lg:gap-28 lg:grid-cols-2 items-center  ${
                index % 2 === 0 ? "" : "lg:order-last"
              }`}
            >
              <div
                className={`relative overflow-hidden h-auto lg:col-span-1 ${
                  index % 2 === 0 ? "lg:order-first" : "lg:order-last"
                }`}
              >
                {projectImages ? (
                  <img
                    alt={section?.heading?.toUpperCase()}
                    src={section?.url}
                    className="aspect-auto object-cover"
                  />
                ) : aboutUs ? (
                  <img
                    alt={section?.tagLine?.toUpperCase()}
                    src={section?.imageUrl}
                    className="aspect-auto object-cover"
                  />
                ) : (
                  <Slider {...settings}>
                    {section?.imageUrl?.map((image, imgIndex) => (
                      <div key={imgIndex}>
                        <img
                          alt={
                            projectImages
                              ? section?.heading?.toUpperCase()
                              : section?.tagLine?.toUpperCase()
                          }
                          src={image}
                          className="w-full h-[35vh] md:h-[50vh] lg:h-[30vh] xl:h-[40vh] 2xl:h-[50vh] 3xl:h-[40vh] 4xl:h-[50vh] object-cover lg:transform lg:hover:scale-105 lg:transition-transform lg:duration-1000"
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>

              <div className="lg:col-span-1 flex flex-col lg:gap-2">
                {projectImages && (
                  <span className="flex items-center">
                    <span className="pr-6 text-gray-400 text-sm">
                      {projectImages
                        ? section?.heading?.toUpperCase()
                        : section?.tagLine?.toUpperCase()}
                    </span>
                    <span className="h-px w-32 bg-gray-300"></span>
                  </span>
                )}

                <h3 className="text-xs md:text-sm text-gray-700">
                  {section?.subtitle?.toUpperCase()}
                </h3>
                <h2 className="font-bold text-base md:text-2xl">
                  {section?.title?.toUpperCase()}
                </h2>
                <p className="text-sm md:text-base font-extralight leading-6 md:leading-7 text-gray-500">
                  {section?.description}
                </p>
                <ul className="text-sm md:text-base font-extralight text-gray-600 flex flex-col gap-2">
                  {section?.features?.map((feature, idx) => (
                    <div key={idx} className="flex gap-2  items-center">
                      <FaCircle className="w-2 h-2" />
                      <li>{feature}</li>
                    </div>
                  ))}
                </ul>
                {!aboutUs && !projectImages && (
                  <Link
                    reloadDocument
                    to={`/area/${section.slug}`}
                    className="mt-3 text-sm md:text-base p-1 md:w-36 flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white"
                  >
                    FIND PROPERTY
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default XploreDubaiImages;
