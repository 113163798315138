import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import black_logo from "../assets/xr_logo_black.png";

function CommunityImages({ imagesWithInfo }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsPerView, setCardsPerView] = useState(4);

  let UpdatedImagesWithInfo = imagesWithInfo;

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 425) {
        setCardsPerView(1);
      } else if (width <= 768) {
        setCardsPerView(2);
      } else if (width <= 1024) {
        setCardsPerView(3);
      } else {
        setCardsPerView(4);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    const maxIndex = Math.max(0, UpdatedImagesWithInfo?.length - cardsPerView);
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const Card = ({ imageWithInfo }) => (
    <div
      className="relative flex-none pt-4 transition-transform hover:-translate-y-2 hover:shadow-2xl rounded-lg"
      style={{
        width: `calc((100% - ${(cardsPerView - 1) * 32}px) / ${cardsPerView})`,
      }}
    >
      <div className="relative border border-gray-300 rounded-lg shadow-lg overflow-visible">
        <div className="overflow-hidden rounded-t-lg">
          <Link reloadDocument to={`/area/${imageWithInfo?.slug}/`}>
            <img
              alt={imageWithInfo?.name}
              src={imageWithInfo?.images[0]?.url}
              className="aspect-video w-full object-cover transform hover:scale-105 transition-transform duration-1000"
            />
          </Link>
        </div>

        {/* Logo Clip */}
        <img
          alt="Clip"
          src={black_logo}
          className="absolute -top-3 right-2 h-6 w-6 md:-top-4 md:right-3 md:h-8 md:w-8 bg-white shadow-md rounded-sm border border-gray-300"
        />

        <div className="py-3 px-4 text-center">
          <dl>
            <div>
              <dt className="sr-only">Title</dt>
              <dd className="text-sm md:text-base text-gray-800 font-semibold truncate">
                {imageWithInfo?.name.toUpperCase()}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative">
      {/* Navigation Arrows */}
      {UpdatedImagesWithInfo?.length > cardsPerView && (
        <>
          {/* <button
            onClick={scrollLeft}
            className={`absolute -left-1 top-1/2 -translate-y-1/2 z-20 text-black ${
              currentIndex === 0
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-gray-700 cursor-pointer"
            }`}
            disabled={currentIndex === 0}
          >
            <FaArrowCircleLeft size={32} />
          </button>
          <button
            onClick={scrollRight}
            className={`absolute -right-1 top-1/2 -translate-y-1/2 z-20 text-black ${
              currentIndex >= UpdatedImagesWithInfo.length - cardsPerView
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-gray-700 cursor-pointer"
            }`}
            disabled={
              currentIndex >= UpdatedImagesWithInfo.length - cardsPerView
            }
          >
            <FaArrowCircleRight size={32} />
          </button> */}
          <button
            onClick={scrollLeft}
            className={`absolute -left-1 top-1/2 -translate-y-1/2 z-20 text-black `}
            disabled={currentIndex === 0}
          >
            <FaArrowCircleLeft size={32} />
          </button>
          <button
            onClick={scrollRight}
            className={`absolute -right-1 top-1/2 -translate-y-1/2 z-20 text-black `}
            disabled={
              currentIndex >= UpdatedImagesWithInfo.length - cardsPerView
            }
          >
            <FaArrowCircleRight size={32} />
          </button>
        </>
      )}

      {/* Cards Container */}
      <div className="mx-8 overflow-hidden ">
        <div
          className="flex gap-8 transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateX(calc(-${currentIndex} * (100% + 32px) / ${cardsPerView}))`,
          }}
        >
          {UpdatedImagesWithInfo?.map((imageWithInfo, index) => (
            <Card key={index} imageWithInfo={imageWithInfo} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommunityImages;
