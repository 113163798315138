import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../api/constants";
import { useLocation, useParams } from "react-router-dom";
import { PiCalendarDotsLight } from "react-icons/pi";
import { LiaTagSolid } from "react-icons/lia";
import LetXpertsInput from "./LetXpertsInput";
import { Helmet } from "react-helmet";

function RenderContent() {
  const { title } = useParams();
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isSmallOrMediumScreen, setIsSmallOrMediumScreen] = useState(false);
  const [modalShownOnce, setModalShownOnce] = useState(false);

  const { pathname } = useLocation();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(BASE_URL + `${title}`);
        setData(response.data.content[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [title]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !modalShownOnce &&
        window.scrollY >= document.documentElement.scrollHeight * 0.5
      ) {
        setShowModal(true);
        setModalShownOnce(true);
      }
    };

    const handleResize = () => {
      setIsSmallOrMediumScreen(window.innerWidth < 1024); // lg breakpoint is 1024px
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [modalShownOnce]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-[100vw] flex items-center justify-center my-16">
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>{data?.seo?.meta_title}</title> */}
        <script type="application/ld+json">
          {JSON.stringify(data?.schema_org?.properties)}
        </script>
      </Helmet>
      <div className="grid grid-cols-1 lg:grid-cols-7 p-1 lg:p-0 lg:w-[80vw] 3xl:w-[68vw]">
        <div className="flex col-span-1 flex-col items-center justify-center lg:col-span-4 2xl:col-span-5">
          {data && (
            <div className=" border">
              <div className="w-full">
                <dl className="p-8 flex flex-col gap-8">
                  <div>
                    <dt className="sr-only">Title</dt>
                    <h1 className="text-xl md:text-2xl lg:text-3xl text-black font-semibold">
                      {data?.title}
                    </h1>
                  </div>
                  <div className="flex gap-4">
                    <dd className="text-base font-light text-gray-700 flex gap-2 items-center">
                      <PiCalendarDotsLight className="w-5 h-5" />
                      {formatDate(data?.publish_date)}
                    </dd>
                    {data?.tags?.length > 0 && (
                      <dd className="text-base font-light text-gray-700 flex gap-1 items-center">
                        <LiaTagSolid className="w-6 h-6" />
                        {data?.tags.join(",")}
                      </dd>
                    )}
                  </div>
                </dl>
                <img
                  src={data.featured_image}
                  alt=""
                  className="w-full aspect-video object-contain"
                />
              </div>
              <div
                className="w-full"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />

              {data?.faqs?.length > 0 && (
                <div
                  className="flow-root pb-8 px-10 md:px-16 lg:px-14"
                  id="faqs"
                >
                  <h1 className="text-xl md:text-2xl lg:text-3xl text-black font-semibold pb-8">
                    FAQ'S
                  </h1>
                  <div className="-my-8 divide-y divide-gray-100">
                    {data?.faqs.map((faq, index) => (
                      <details
                        key={index}
                        className="group py-8 [&_summary::-webkit-details-marker]:hidden"
                      >
                        <summary className="flex cursor-pointer items-center justify-between text-gray-900">
                          <h2 className="text-base xl:text-lg font-bold">
                            {faq.question}
                          </h2>
                          <span className="relative size-5 shrink-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute inset-0 size-5 opacity-100 group-open:opacity-0"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute inset-0 size-5 opacity-0 group-open:opacity-100"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                        </summary>
                        <p className="mt-4 leading-relaxed text-gray-700">
                          {faq.answer}
                        </p>
                      </details>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-span-1 lg:col-span-3 2xl:col-span-2 mt-8 lg:mt-0 lg:ml-8 p-8 border h-fit">
          {(!isSmallOrMediumScreen || !showModal) && (
            <LetXpertsInput theme="light" />
          )}
        </div>
      </div>
      {isSmallOrMediumScreen && showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white px-8 rounded  relative">
            <button
              className="absolute top-2 right-2 text-gray-700 text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <LetXpertsInput theme="light" />
          </div>
        </div>
      )}
    </div>
  );
}

export default RenderContent;
