import React from "react";
import FormatWidget from "./FormatWidget";
import NewsAndInsightsImages from "./NewsAndInsightsImages";

function Projects({ imagesWithInfo }) {
  return (
    <FormatWidget
      title="XPLORE OUR DUBAI PROPERTY BLOG"
      linkText="VIEW ALL"
      linkTo="/blogs/"
      newAndInsights={true}
    >
      <NewsAndInsightsImages imagesWithInfo={imagesWithInfo} />
    </FormatWidget>
  );
}

export default Projects;
