// import React from "react";
// import XploreDubaiImages from "../components/XploreDubaiImages";
// import FormatWidget from "../components/FormatWidget";
// import OfficePics from "../components/OfficePics";
// import { Helmet } from "react-helmet";
// import { BASE_URL, WEB_URL } from "../api/constants";
// import { useLocation } from "react-router-dom";
// import ScrollTriggerModal from "../components/ScrollTriggerModal";
// import LetXpertsInput from "../components/LetXpertsInput";

// const sections = [
//   {
//     title: "About Us",
//     description:
//       "In a world where properties have stories to tell, we’re here to lead you through the chapters of opportunity, growth, and transformation. Our carefully selected team of real estate agents is committed to transforming your dreams into reality. We apply the principle of continuous booming and offer an all-encompassing curriculum of real estate knowledge that equips you to navigate the dynamic industry landscape. Our expertise stretches beyond the boundaries of a conventional company; we offer you the chance to excel in the world of real estate. As you sink yourself in our expansive range of insights, you’ll find yourself participating in game-changing deals that shape your future.",
//     imageUrl:
//       "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/21111014/about-us-xperience-realty-real-estate-dubai.webp",
//   },
//   {
//     title: "Our Goal",
//     description:
//       "Rendering our passion into action by empowering our agents with advanced training, ensuring top-notch customer service and brand preference. As we fine-tune our approach, we’re on a mission to establish ourselves as Dubai’s unmatched and forward-thinking real estate consultancy, driven to deliver the utmost value for all stakeholders within and beyond our organization.",
//     imageUrl:
//       "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/21114535/about-burj-a-arab-xperience-realty-real-estate-dubai.jpeg",
//   },
//   {
//     title: "Emaar Awards",
//     description:
//       "In 2024, Xperience Realty was proudly ranked among the top 10 in Emaar’s Annual Broker Awards 2023, highlighting its position as a leading Dubai real estate firm and a top Emaar alliance partner. This recognition underscores Xperience Realty’s commitment to excellence, supported by Emaar and driven by its dedicated team.The award reaffirms the firm’s standing as one of Dubai’s premier real estate agencies, a testament to its consistent efforts and high-quality service.",
//     imageUrl:
//       "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/21112312/about-us-company-bg-xperience-realty-real-estate-dubai.png",
//   },
// ];

// function AboutUs() {
//   const { pathname } = useLocation();

//   return (
//     <>
//       <Helmet>
//         {/* <title>
//           About Us | Xperience Realty | Real estate company in Dubai
//         </title> */}
//         <script type="application/ld+json">
//           {JSON.stringify({
//             "@context": "https://schema.org",
//             "@type": "AboutPage",
//             name: "About Us | Xperience Realty | Real Estate Dubai",
//             description:
//               "Learn more about Xperience Realty, a leading real estate agency in Dubai. Discover our mission, vision, and the team behind our success.",
//             image:
//               "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",

//             url: WEB_URL + pathname.slice(1),
//           })}
//         </script>
//       </Helmet>
//       <ScrollTriggerModal triggerPoints={[0.3]}>
//         <LetXpertsInput theme="light" />
//       </ScrollTriggerModal>
//       {/* <div >
//         <FormatWidget
//           title={
//             <div>
//               <h2 className="text-base md:text-2xl">Experience the Xperience</h2>
//             </div>
//           }
//           subtitle={
//             <div
//               className="text-sm md:text-base font-light leading-5 text-gray-600"
//               initial="hidden"
//               animate="visible"
//               // variants={subtitleVariants}
//             >
//               <p>
//                 "Xperience Realty is where the concept of excellence in Dubai
//                 real estate consultation reaches new heights. Beyond being a
//                 mere company, we proudly identify ourselves as a university of
//                 knowledge and innovation in the world of real estate. Just as
//                 our name implies, we stand as a shining example of distinction
//                 within the elite world of high-profile real estate guidance.
//                 Building upon a foundation of rich experience and an
//                 irreplaceable commitment to excellence, we have birthed XR as a
//                 brand synonymous with quality."
//               </p>
//             </div>
//           }
//         >
//           <div>
//             <XploreDubaiImages sections={sections} aboutUs="true" />
//           </div>
//         </FormatWidget>
//       </div> */}

//       {/* <div className="flex flex-col justify-center items-center gap-8 mb-8 lg:gap-16 lg:mb-16">
//         <div className="aspect-video lg:h-[70dvh] w-full">
//           <img
//             className="h-full w-full object-cover"
//             src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724233044/xr_media/wslc1nljodzbxxfhq916.jpg"
//             alt=""
//           />
//         </div>

//         <div className="flex flex-col justify-center items-center w-[90%] lg:w-[45%] gap-4">
//           <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center">
//             EXPERIENCE THE XPERIENCE
//           </h2>
//           <p className="text-center text-sm md:text-base font-light">
//             Xperience Realty is where the concept of excellence in Dubai real
//             estate consultation reaches new heights. Beyond being a mere
//             company, we proudly identify ourselves as a university of knowledge
//             and innovation in the world of real estate. Just as our name
//             implies, we stand as a shining example of distinction within the
//             elite world of high-profile real estate guidance. Building upon a
//             foundation of rich experience and an irreplaceable commitment to
//             excellence, we have birthed XR as a brand synonymous with quality.
//           </p>
//         </div>

//         <div className="w-full border-t-2 border-dotted border-gray-400 "></div>

//         <div className="grid lg:grid-cols-2 justify-start w-[90%] gap-10 lg:gap-40  3xl:gap-64 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] overflow-hidden items-start">
//           <div className="grid gap-4 col-span-2 lg:col-span-1">
//             <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center lg:text-start">
//               ABOUT US
//             </h2>
//             <p className=" text-sm md:text-base font-light text-center lg:text-justify">
//               In a world where properties have stories to tell, we’re here to
//               lead you through the chapters of opportunity, growth, and
//               transformation. Our carefully selected team of real estate agents
//               is committed to transforming your dreams into reality. We apply
//               the principle of continuous booming and offer an all-encompassing
//               curriculum of real estate knowledge that equips you to navigate
//               the dynamic industry landscape. Our expertise stretches beyond the
//               boundaries of a conventional company; we offer you the chance to
//               excel in the world of real estate. As you sink yourself in our
//               expansive range of insights, you’ll find yourself participating in
//               game-changing deals that shape your future.
//             </p>
//           </div>
//         </div>

//         <div className="aspect-video w-full lg:hidden">
//           <img
//             className="h-full w-full object-cover"
//             src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724242152/xr_media/s22orxegfjzz6dta19er.jpg"
//             alt=""
//           />
//         </div>

//         <div className="grid lg:grid-cols-2 justify-start w-[90%] gap-10 lg:gap-40  3xl:gap-64 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] overflow-hidden items-start">
//           <div className="grid gap-4 col-span-2 lg:col-span-1  ">
//             <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center lg:text-start">
//               OUR GOAL
//             </h2>
//             <p className=" text-sm md:text-base font-light text-center lg:text-justify">
//               Rendering our passion into action by empowering our agents with
//               advanced training, ensuring top-notch customer service and brand
//               preference. As we fine-tune our approach, we’re on a mission to
//               establish ourselves as Dubai’s unmatched and forward-thinking real
//               estate consultancy, driven to deliver the utmost value for all
//               stakeholders within and beyond our organization.
//             </p>
//           </div>
//         </div>

//         <div className=" aspect-video lg:h-[70dvh] w-full">
//           <img
//             className="h-full w-full object-cover"
//             src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724233283/xr_media/eopvq6jzdaryi3hldhn4.jpg "
//             alt=""
//           />
//         </div>

//         <div className="flex flex-col justify-center items-center w-[90%] lg:w-[45%] gap-4">
//           <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center">
//             EMAAR AWARDS
//           </h2>
//           <p className="text-center text-sm md:text-base font-light">
//             In 2024, Xperience Realty was proudly ranked among the top 10 in
//             Emaar’s Annual Broker Awards 2023, highlighting its position as a
//             leading Dubai real estate firm and a top Emaar alliance partner.
//             This recognition underscores Xperience Realty’s commitment to
//             excellence, supported by Emaar and driven by its dedicated team.The
//             award reaffirms the firm’s standing as one of Dubai’s premier real
//             estate agencies, a testament to its consistent efforts and
//             high-quality service.
//           </p>
//         </div>
//       </div> */}

//     </>
//   );
// }

// export default AboutUs;

import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ScrollTriggerModal from "../components/ScrollTriggerModal";
import LetXpertsInput from "../components/LetXpertsInput";
import { WEB_URL } from "../api/constants";

function AboutUs() {
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AboutPage",
            name: "About Us | Xperience Realty | Real Estate Dubai",
            description:
              "Learn more about Xperience Realty, a leading real estate agency in Dubai. Discover our mission, vision, and the team behind our success.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.3]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}

      <div className="flex flex-col justify-center items-center gap-8 mb-8 md:gap-16  md:mb-16">
        <div className=" lg:h-[70dvh] w-full">
          <img
            className="h-full w-full object-cover"
            src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724746380/xr_media/fupdihpsnq6zegrufssi.jpg"
            alt=""
          />
        </div>

        <div className="flex flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[60%] 3xl:w-[45%] gap-4">
          <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center">
            EXPERIENCE THE XPERIENCE
          </h2>
          <p className="text-center text-sm md:text-base font-light">
            Xperience Realty is where the concept of excellence in Dubai real
            estate consultation reaches new heights. Beyond being a mere
            company, we proudly identify ourselves as a university of knowledge
            and innovation in the world of real estate. Just as our name
            implies, we stand as a shining example of distinction within the
            elite world of high-profile real estate guidance. Building upon a
            foundation of rich experience and an irreplaceable commitment to
            excellence, we have birthed XR as a brand synonymous with quality.
          </p>
        </div>

        <div className="w-full border-t-2 border-dotted border-gray-400"></div>

        {/* Content for small/medium screens */}
        <div className="flex flex-col lg:hidden gap-8 items-center">
          <div className="flex flex-col items-center gap-4 w-[90%] md:w-[80%]">
            <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center">
              ABOUT US
            </h2>
            <p className="text-sm md:text-base font-light text-center">
              In a world where properties have stories to tell, we’re here to
              lead you through the chapters of opportunity, growth, and
              transformation. Our carefully selected team of real estate agents
              is committed to transforming your dreams into reality. We apply
              the principle of continuous booming and offer an all-encompassing
              curriculum of real estate knowledge that equips you to navigate
              the dynamic industry landscape. Our expertise stretches beyond the
              boundaries of a conventional company; we offer you the chance to
              excel in the world of real estate. As you sink yourself in our
              expansive range of insights, you’ll find yourself participating in
              game-changing deals that shape your future.
            </p>
          </div>

          <div className="w-full">
            <img
              className="h-full w-full object-cover"
              src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724751122/xr_media/grfhy5stt7ufkj3i1q5c.jpg"
              alt=""
            />
          </div>

          <div className="flex flex-col items-center gap-4 w-[90%] md:w-[80%] ">
            <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center">
              OUR GOAL
            </h2>
            <p className="text-sm md:text-base font-light text-center">
              {/* Rendering our passion into action by empowering our agents with
              advanced training, ensuring top-notch customer service and brand
              preference. As we fine-tune our approach, we’re on a mission to
              establish ourselves as Dubai’s unmatched and forward-thinking real
              estate consultancy, driven to deliver the utmost value for all
              stakeholders within and beyond our organization. */}
              Rendering our passion into action by empowering our agents with
              advanced training, ensuring top-notch customer service and strong
              brand preference. We foster a culture of innovation and excellence
              that equips our team with the skills and knowledge needed to
              exceed industry standards consistently. As we fine-tune our
              approach, we’re on a mission to establish ourselves as Dubai’s
              unmatched and forward-thinking real estate consultancy, driven to
              deliver the utmost value for all stakeholders within and beyond
              our organization. Committed to integrity and transparency, we
              strive to create lasting relationships and transformative
              experiences that redefine the future of real estate services.
            </p>
          </div>
        </div>

        {/* Content for large screens and above */}
        <div className="hidden lg:grid lg:grid-cols-2  lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] gap-10 lg:gap-40 3xl:gap-64 items-start">
          <div className="col-span-1 gap-4 flex flex-col">
            <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-start">
              ABOUT US
            </h2>
            <p className="text-sm md:text-base font-light text-justify">
              In a world where properties have stories to tell, we’re here to
              lead you through the chapters of opportunity, growth, and
              transformation. Our carefully selected team of real estate agents
              is committed to transforming your dreams into reality. We apply
              the principle of continuous booming and offer an all-encompassing
              curriculum of real estate knowledge that equips you to navigate
              the dynamic industry landscape. Our expertise stretches beyond the
              boundaries of a conventional company; we offer you the chance to
              excel in the world of real estate. As you sink yourself in our
              expansive range of insights, you’ll find yourself participating in
              game-changing deals that shape your future.
            </p>
          </div>

          <div className="col-span-1  gap-4 flex flex-col">
            <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-start">
              OUR GOAL
            </h2>
            <p className="text-sm md:text-base font-light text-justify">
              {/* Rendering our passion into action by empowering our agents with
              advanced training, ensuring top-notch customer service and brand
              preference. As we fine-tune our approach, we’re on a mission to
              establish ourselves as Dubai’s unmatched and forward-thinking real
              estate consultancy, driven to deliver the utmost value for all
              stakeholders within and beyond our organization. */}
              Rendering our passion into action by empowering our agents with
              advanced training, ensuring top-notch customer service and strong
              brand preference. We foster a culture of innovation and excellence
              that equips our team with the skills and knowledge needed to
              exceed industry standards consistently. As we fine-tune our
              approach, we’re on a mission to establish ourselves as Dubai’s
              unmatched and forward-thinking real estate consultancy, driven to
              deliver the utmost value for all stakeholders within and beyond
              our organization. Committed to integrity and transparency, we
              strive to create lasting relationships and transformative
              experiences that redefine the future of real estate services.
            </p>
          </div>
        </div>

        <div className="lg:h-[70dvh] w-full">
          <img
            className="h-full w-full object-cover"
            src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724746212/xr_media/hlb70npk0vcjijmqqn6m.jpg"
            alt=""
          />
        </div>

        <div className="flex flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[60%] 3xl:w-[45%] gap-4">
          <h2 className="tracking-[0.8rem] text-base md:text-2xl font-light text-center">
            EMAAR AWARDS
          </h2>
          <p className="text-center text-sm md:text-base font-light">
            In 2024, Xperience Realty was proudly ranked among the top 10 in
            Emaar’s Annual Broker Awards 2023, highlighting its position as a
            leading Dubai real estate firm and a top Emaar alliance partner.
            This recognition underscores Xperience Realty’s commitment to
            excellence, supported by Emaar and driven by its dedicated team.The
            award reaffirms the firm’s standing as one of Dubai’s premier real
            estate agencies, a testament to its consistent efforts and
            high-quality service.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
