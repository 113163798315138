// import React from "react";
// import LetXpertsInput from "./LetXpertsInput";
// import { FaWhatsapp } from "react-icons/fa";
// import { MdOutlineMailOutline } from "react-icons/md";

// function RegisterInterest({ theme }) {
//   return (
//     <div className="grid grid-cols-1 lg:grid-cols-2 bg-black items-center ">
//       {/* <div className=" bg-black lg:col-span-1 order-1 lg:order-2 my-8 lg:mx-4">
//         <div className="lg:col-span-2 order-1 lg:order-2 bg-black text-white gap-10 flex flex-col">
//           <div className="flex flex-col gap-2">
//             <h2 className="text-xl md:text-2xl ">
//               REGISTER YOUR INTEREST
//             </h2>
//             <p className="text-base ">
//               To find out about availability, contact a member of our team or
//               fill out the form and we’ll be in touch.
//             </p>
//           </div>
//           <div className="w-[70%]">
//             <hr className="text-white"/>
//           </div>

//           <div className="flex justify-between gap-3 w-[70%]">
//             <p className="text-lg lg:text-lg mb-4 flex gap-2 items-center">
//               <FaWhatsapp className="h-8 w-8" />
//               <a href="https://wa.me/+971564080888">Click to chat</a>
//             </p>
//             <p className="text-lg lg:text-lg mb-4 flex gap-2 items-center">
//               <MdOutlineMailOutline className="h-8 w-8" />
//               <a href="mailto:info@xrealty.ae">info@xrealty.ae</a>
//             </p>
//           </div>
//         </div>
//       </div> */}
//       <div className="lg:w-full lg:h-[50vh]">
//         <img src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724751122/xr_media/kqsxb8ecqyxqz1mkkxo6.png" alt="" className="lg:w-full lg:h-full object-cover"/>
//       </div>
//       <div className=" bg-black lg:col-span-1  order-2 flex flex-col justify-center items-center">
//         <section className=" bg-black w-[85%] md:w-[80%]  xl:w-[70%] 2xl:w-[60%] 3xl:w-[50%]">
//           <div className="lg:grid  lg:grid-cols-12">
//             <main className="flex items-center justify-center lg:col-span-12 ">
//               <div className="w-full ">
//                 <LetXpertsInput theme={theme} registerInterest={true} />
//               </div>
//             </main>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// }

// export default RegisterInterest;

import React from "react";
import LetXpertsInput from "./LetXpertsInput";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

function RegisterInterest({ theme }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 bg-gray-100 items-center ">
      {/* <div className=" bg-black lg:col-span-1 order-1 lg:order-2 my-8 lg:mx-4">
        <div className="lg:col-span-2 order-1 lg:order-2 bg-black text-white gap-10 flex flex-col">
          <div className="flex flex-col gap-2">
            <h2 className="text-xl md:text-2xl ">
              REGISTER YOUR INTEREST
            </h2>
            <p className="text-base ">
              To find out about availability, contact a member of our team or
              fill out the form and we’ll be in touch.
            </p>
          </div>
          <div className="w-[70%]">
            <hr className="text-white"/>
          </div>

          <div className="flex justify-between gap-3 w-[70%]">
            <p className="text-lg lg:text-lg mb-4 flex gap-2 items-center">
              <FaWhatsapp className="h-8 w-8" />
              <a href="https://wa.me/+971564080888">Click to chat</a>
            </p>
            <p className="text-lg lg:text-lg mb-4 flex gap-2 items-center">
              <MdOutlineMailOutline className="h-8 w-8" />
              <a href="mailto:info@xrealty.ae">info@xrealty.ae</a>
            </p>
          </div>
        </div>
      </div> */}
      <div className="lg:w-full 2xl:h-[60vh] 3xl:h-[50vh] ">
        <img
          src="https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/v1724751122/xr_media/kqsxb8ecqyxqz1mkkxo6.png"
          alt=""
          className="lg:w-full lg:h-full object-cover"
        />
      </div>
      <div className=" bg-gray-100 lg:col-span-1  order-2 flex flex-col justify-center items-center">
        <section className=" bg-gray-100 w-[85%] md:w-[80%]  xl:w-[70%] 2xl:w-[60%] 3xl:w-[50%]">
          <div className="lg:grid  lg:grid-cols-12">
            <main className="flex items-center justify-center lg:col-span-12 ">
              <div className="w-full ">
                <LetXpertsInput theme={theme} registerInterest={true} />
              </div>
            </main>
          </div>
        </section>
      </div>
    </div>
  );
}

export default RegisterInterest;
