import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { FETCH_All_DEVELOPERS } from "../api/constants";

const initialMenuItems = [
  { name: "HOME", href: "/" },
  {
    name: "DEVELOPERS",
    subMenu: [], // Initial empty subMenu
  },
  { name: "ABOUT US", href: "/about-us/" },
  { name: "CONTACT US", href: "/contact-us-dubai-real-estate-agency/" },
  {
    name: "MORE",
    subMenu: [
      { name: "XR TEAM", href: "/agent/" },
      {
        name: "MEDIA CENTER",
        subMenu: [
          {
            name: "NEWS & INSIGHTS",
            href: "/real-estate-news/",
          },
          { name: "BLOGS", href: "/blogs/" },
        ],
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [menuItems, setMenuItems] = useState(initialMenuItems);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(FETCH_All_DEVELOPERS);
        const developersData = response.data.data;

        const developersSubMenu = developersData.map((developer) => ({
          name: developer.developer_name.toUpperCase(),
          href: `/developer/${developer.developer_slug}/`,
        }));

        setMenuItems((prevMenuItems) =>
          prevMenuItems.map((item) =>
            item.name === "DEVELOPERS"
              ? { ...item, subMenu: developersSubMenu }
              : item
          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const SubMenuItem = ({ item }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="group relative flex items-center gap-x-6 p-2 text-sm leading-6 hover:bg-[#111111] hover:text-white cursor-pointer">
          {item.href ? (
            <Link
              reloadDocument
              // to={
              //   item.name !== "XR TEAM"
              //     ? `${item.href}?page=1&limit=8`
              //     : `${item.href}`
              // }

              to={item.href}
              className="flex-grow"
            >
              {item.name}
            </Link>
          ) : (
            <>
              <span className="flex-grow">{item.name}</span>
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </>
          )}
        </div>

        {item.subMenu && isHovered && (
          <div className="absolute left-full top-0 z-50 -ml-px mt-0 w-48 bg-white shadow-lg ring-1 ring-gray-900/5">
            {item.subMenu.map((subItem) => (
              <Link
                reloadDocument
                key={subItem.name}
                to={subItem.href}
                className="block p-2 text-sm leading-6 hover:bg-[#111111] hover:text-white"
              >
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderSubMenu = (subItems) => (
    <div className="relative">
      {subItems.map((subItem) => (
        <SubMenuItem key={subItem.name} item={subItem} />
      ))}
    </div>
  );

  const renderMobileSubMenu = (subItems, level = 0) => (
    <>
      {subItems.map((subItem) =>
        subItem.subMenu ? (
          <Disclosure as="div" key={subItem.name} className="ml-4">
            {({ open }) => (
              <>
                <DisclosureButton className="flex w-full items-center justify-between py-2 pl-3 pr-3 text-base font-semibold leading-7 text-black hover:bg-gray-400">
                  {subItem.name}
                  <ChevronDownIcon
                    className={classNames(
                      open ? "rotate-180" : "",
                      "h-5 w-5 flex-none"
                    )}
                    aria-hidden="true"
                  />
                </DisclosureButton>
                <DisclosurePanel className="mt-2 space-y-2">
                  {renderMobileSubMenu(subItem.subMenu, level + 1)}
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
        ) : (
          <Link
            reloadDocument
            key={subItem.name}
            // to={
            //   subItem.name === "XR TEAM" ||
            //   subItem.name === "NEWS & INSIGHTS" ||
            //   subItem.name === "BLOGS"
            //     ? `${subItem.href}`
            //     : `${subItem.href}?page=1&limit=8`
            // }
            to={subItem.href}
            onClick={handleLinkClick}
            className={`block rounded-lg py-2 pl-${
              6 + level * 3
            } pr-3 text-sm font-semibold leading-7 text-black hover:bg-gray-400`}
          >
            {subItem.name}
          </Link>
        )
      )}
    </>
  );

  return (
    <header className="bg-black sticky top-0 z-50">
      <nav
        className="mx-auto flex max-w-full items-center lg:justify-around justify-between py-3 px-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex">
          <Link to="/" className="-m-1.5 p-1.5" reloadDocument>
            <img
              className="h-10 w-auto lg:h-[4.4rem] md:h-14 mr-2"
              src="https://res.cloudinary.com/dkhns25jh/image/upload/v1733821293/xr_media/sqmxma6remiuh1mbv4x7.png"
              alt="xrealty-logo-light"
            />
          </Link>
        </div>

        <div className="flex lg:hidden">
          <div className="lg:flex lg:flex-1 lg:justify-end md:h-10 mr-4">
            <a
              href="https://aocl.short.gy/playstore"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dkhns25jh/image/upload/v1733821355/xr_media/rhhrkimwwvuxyfpovolt.png"
                alt="Logo"
                className="h-5 m-1 md:h-8 xl:h-10 2xl:h-12"
              />
            </a>
          </div>
          <div className="lg:flex lg:flex-1 lg:justify-end mr-5">
            <a
              href="https://aocl.short.gy/appstore"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dkhns25jh/image/upload/v1733821424/xr_media/rvy8ppfyxeaqa1krhstk.svg"
                alt="Logo"
                className="h-5 m-1 md:h-8 xl:h-10 2xl:h-12"
              />
            </a>
          </div>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              className="w-7 h-7 md:h-8 md:w-8 p-1 border border-white hover:bg-white hover:text-black"
              aria-hidden="true"
            />
          </button>
        </div>

        <PopoverGroup className="hidden lg:flex lg:gap-x-2 leading-6 items-center">
          {menuItems.map((item) =>
            item.subMenu ? (
              <Popover key={item.name} className="relative">
                {({ open, close }) => (
                  <>
                    <PopoverButton className="flex items-center gap-x-1 text-xs xl:text-sm font-normal px-3 py-2 leading-6 text-white hover:bg-white hover:text-black">
                      {item.name}
                      <ChevronDownIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </PopoverButton>

                    <Transition
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <PopoverPanel className="absolute -left-8 top-full z-50 mt-3 w-48 overflow-visible bg-white shadow-lg ring-1 ring-gray-900/5">
                        {renderSubMenu(item.subMenu)}
                      </PopoverPanel>
                    </Transition>
                  </>
                )}
              </Popover>
            ) : (
              <Link
                reloadDocument
                key={item.name}
                to={item.href}
                className={classNames(
                  location.pathname === item.href
                    ? "text-black bg-white"
                    : "text-white hover:bg-white hover:text-black",
                  "text-xs xl:text-sm font-normal px-3 py-2"
                )}
              >
                {item.name}
              </Link>
            )
          )}

          <div className="flex ml-20">
            <div className="lg:flex lg:flex-1 lg:justify-end md:h-10 mr-4">
              <a
                href="https://aocl.short.gy/playstore"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14161616/googlestore.png"
                  alt="Logo"
                  className="h-5 m-1 md:h-8 xl:h-10 2xl:h-12"
                />
              </a>
            </div>
            <div className="lg:flex lg:flex-1 lg:justify-end">
              <a
                href="https://aocl.short.gy/appstore"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14161641/app-store-logo-vector.svg"
                  alt="Logo"
                  className="h-5 m-1 md:h-8 xl:h-10 2xl:h-12"
                />
              </a>
            </div>
          </div>
        </PopoverGroup>
      </nav>

      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-full sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between bg-[#111111] py-3 px-4">
            <Link to="/" className="-m-1.5 p-1.5" reloadDocument>
              <img
                className="h-10 md:h-14 w-auto"
                src="https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14160533/logo-light-web.png"
                alt="xrealty-logo-light"
              />
            </Link>

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon
                className="h-6 w-6 md:h-8 md:w-8 p-1 border border-white hover:bg-white hover:text-black"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="space-y-1 py-6 px-4">
              {menuItems.map((item) =>
                item.subMenu ? (
                  <Disclosure as="div" key={item.name}>
                    {({ open }) => (
                      <>
                        <DisclosureButton className="flex w-full items-center justify-between py-2 pl-3 pr-3 text-base font-semibold leading-7 text-black hover:bg-gray-400">
                          {item.name}
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">
                          {renderMobileSubMenu(item.subMenu)}
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                ) : (
                  <Link
                    reloadDocument
                    key={item.name}
                    to={item.href}
                    onClick={handleLinkClick}
                    className="flex w-full items-center justify-between py-2 pl-3 pr-3 text-base font-semibold leading-7 text-black hover:bg-gray-400"
                  >
                    {item.name}
                  </Link>
                )
              )}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
