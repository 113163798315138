// import React from "react";
// import { FaPhoneAlt } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import CustomPagination from "./CustomPagination"; // Adjust the import path as necessary

// function XrTeamImages({
//   currentImages,
//   currentPage,
//   totalPages,
//   handlePageChange,
// }) {
//   return (
//     <>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
//         {currentImages?.map((image, index) => (
//           <div
//             key={index}
//             className="h-auto flex flex-col shadow-sm shadow-black bg-black text-white"
//           >
//             <Link
//               reloadDocument
//               to={`/agent/${image?.name_slug}`}
//               className="block overflow-hidden shadow-2xl relative h-[50vh]"
//             >
//               <img
//                 alt=""
//                 src={image?.profile_picture}
//                 className="w-full h-full object-cover object-top transition-transform duration-500"
//               />
//             </Link>

//             <div className="flex justify-between items-center p-5 overflow-auto ">
//               <dl className="h-24 justify-center flex flex-col w-2/3 ">
//                 <div>
//                   <dt className="sr-only">Name</dt>
//                   <dd className="text-2xl lg:text-lg xl:text-xl 2xl:text-2xl text-ellipsis overflow-clip">
//                     {image?.name}{" "}
//                   </dd>
//                 </div>
//                 <div>
//                   <dt className="sr-only">Languages</dt>
//                   <dd className="text-xl lg:text-base xl:text-lg 2xl:text-xl text-ellipsis overflow-clip">
//                     {image?.languages?.join(", ")}{" "}
//                   </dd>
//                 </div>
//               </dl>
//               <dl>
//                 <a
//                   href={`tel:${image?.phone}`}
//                   className="border border-white w-12 h-12 p-2 flex justify-center items-center"
//                 >
//                   <FaPhoneAlt />
//                 </a>
//               </dl>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Pagination Component */}
//       <div className="mt-6 flex justify-center">
//         <CustomPagination
//           currentPage={currentPage}
//           totalPages={totalPages}
//           onPageChange={handlePageChange}
//         />
//       </div>
//     </>
//   );
// }

// export default XrTeamImages;

import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function XrTeamImages({ currentImages }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
      {currentImages?.map((image, index) => (
        <div
          key={index}
          className="flex flex-col shadow-sm shadow-black bg-black text-white"
        >
          <Link
            reloadDocument
            to={`/agent/${image?.name_slug}/`}
            className="block overflow-hidden shadow-2xl relative h-[50vh]"
          >
            <img
              alt=""
              src={image?.profile_picture}
              className="w-full h-full object-cover object-top transition-transform duration-500"
            />
          </Link>

          <div className="flex justify-between items-center p-5 overflow-auto ">
            <dl className="h-full justify-center flex flex-col w-2/3 ">
              <div>
                <dt className="sr-only">Name</dt>
                <dd className="text-base  2xl:text-xl text-ellipsis overflow-clip">
                  {image?.name}
                </dd>
              </div>
              <div>
                <dt className="sr-only">Languages</dt>
                <dd className="text-sm  2xl:text-lg text-ellipsis overflow-clip">
                  {image?.languages?.join(", ")}
                </dd>
              </div>
            </dl>
            <dl>
              <a
                href={`tel:${image?.phone}`}
                className="border border-white w-12 h-12 p-2 flex justify-center items-center"
              >
                <FaPhoneAlt />
              </a>
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
}

export default XrTeamImages;
