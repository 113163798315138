import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import FormatWidget from "../components/FormatWidget";
import ContactUsForm from "../components/ContactUsForm";
import ContactUsCareersPage from "../components/ContactUsCareersPage";

const contactDetails = [
  {
    label: "Phone",
    value: "+971564080888",
    icon: "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14130804/phone.svg",
    action: "tel:$+971564080888",
  },
  {
    label: "Landline",
    value: "+97147049400",
    icon: "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14113946/telephone.svg",
    action: "tel:$+97147049400",
  },
  {
    label: "Email",
    value: "info@xrealty.ae",
    icon: "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14132126/open-mail.svg",
    action: "mailto:info@xrealty.ae",
  },
  {
    label: "Office Address",
    value:
      "Office no, 301 & 302, Level 3, Building 1, Park Heights Square 1, Dubai Hills Estate, Dubai, UAE PO Box: 65777",
    icon: "https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/14132439/home.svg",
    action:
      "https://www.google.com/maps/place/Xperience%E2%80%8B+Realty/@25.1143597,55.2437603,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f69742ae364c3:0xf5f30b07708195fd!8m2!3d25.1143549!4d55.2463352!16s%2Fg%2F11ty7cwknc?entry=tts&shorturl=1",
  },
];

const formFields = [
  { placeholder: "First Name", type: "text", id: "firstname" },
  { placeholder: "Last Name", type: "text", id: "lastname" },
  { placeholder: "Email address", type: "email", id: "email" },
  { placeholder: "Phone Number", type: "tel", id: "phone" },
];

const WorKWithUsSections = ({ icon, heading, paragraph }) => {
  return (
    <div className="flex flex-row items-start gap-3">
      {/* <div>{icon}</div> */}
      <div className="flex flex-col gap-3">
        <h2 className="font-bold">{heading}</h2>
        <p className="leading-7 font-light text-sm md:text-base text-justify">
          {paragraph}
        </p>
      </div>
    </div>
  );
};

export default function Careers() {
  return (
    <>
      <div className="h-[50vh] md:h-[40vh] xl:h-[50vh] relative">
        <img
          src="https://res.cloudinary.com/dkhns25jh/image/upload/v1732626271/xr_media/pc4opfh5ras7kqcdowao.png"
          className="object-cover md:object-fill h-full w-full"
          alt="careers-page"
        />
        <div className=" mt-5 md:mt-0 absolute inset-0 flex flex-col justify-center items-center w-full h-full">
          <div className="flex flex-col items-center w-10/12 md:w-2/4 lg:w-2/4 3xl:w-2/6 mb-24">
            <h1 className="font-normal text-2xl md:text-4xl lg:text-5xl 3xl:text-5xl mb-7 tracking-wide text-white">
              Careers
            </h1>
            {/* <p className="leading-7 font-light text-white text-center text-sm md:text-base">
              If You Have The Spirit, We Have The Support. <br />
              Xperience Realty With The Real State of Mind..!
            </p> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-10">
        {/* <div className="flex flex-col items-center w-2/6 mb-24">
          <h1 className="font-normal text-4xl mb-7 tracking-wide ">Careers</h1>
          <p className="leading-7 font-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
            ex dolor, quam dignissimos vitae dolorem! Tempora commodi vel
            corporis dolorum, praesentium unde, quibusdam sit consectetur
            deserunt magni voluptates quas exercitationem?
          </p>
        </div> */}

        {/* Why work with us */}
        <div className="flex flex-col items-center w-[85%] md:w-[88%] lg:w-[90%] 2xl:w-[80%] 3xl:w-[70%] mb-16">
          <h1 className="font-normal text-xl md:text-3xl lg:text-4xl 3xl:text-4xl mb-10 tracking-wide">
            Why Work With us?
          </h1>
          <div className="flex flex-col gap-4 lg:flex-row lg:gap-7 3xl:gap-24">
            <WorKWithUsSections
              icon={<FaInfoCircle size={30} />}
              heading={"Industry-Leading Growth & Learning"}
              paragraph={
                "XR Academy training gives exclusive access to advanced skills, hands-on mentorship, and insights from the creators of Xperience Realty who were once the market heroes. Make yourself lead in this flourishing industry by making continuous learning a culture."
              }
            />
            <WorKWithUsSections
              icon={<FaInfoCircle size={30} />}
              heading={"Global Clients & Expansive Networking"}
              paragraph={
                "Being a leading real estate agency in Dubai, Xperience Realty opens the door to a global client base. Work with international investors, high-net-worth individuals, and global buyers, being a matchmaker that assures a rewarding future in Dubai."
              }
            />
            <WorKWithUsSections
              icon={<FaInfoCircle size={30} />}
              heading={"Alliance with Leading Developers"}
              paragraph={
                "Collaborate with Dubai’s most prestigious real estate developers in Dubai. As the top real estate brokers in Dubai, we exclusively represent premium projects from the industry giants by giving you access to deal with the city’s most iconic developments."
              }
            />
          </div>
        </div>

        {/* Contact Us */}
        <div className="w-11/12 md:w-11/12 lg:w-11/12">
          <FormatWidget
            title="Contact Us"
            subtitle={
              <p className="text-black leading-7 text-sm md:text-base">
                Are you ready to take the next step in your career? <br />
                Join our team of passionate professionals and be part of a
                dynamic, innovative environment. Whether you’re a seasoned
                expert or just starting out, we’d love to hear from you. Feel
                free to reach out to us via email, phone, or by completing our
                application form for current opportunities or general inquiries
                about working with us.
              </p>
            }
          >
            <div className="mt-3">
              <ContactUsCareersPage
                formFields={formFields}
                contactDetails={contactDetails}
              />
            </div>
          </FormatWidget>
        </div>
      </div>
      <section className="mt-12">
        <iframe
          title="XR location"
          width="100%"
          height="600"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=xperiance%20realty+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps devices</a>
        </iframe>
      </section>
    </>
  );
}
