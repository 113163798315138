import React, { useState } from "react";

import axios from "axios";
import { CONTACT_US } from "../api/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

function ContactUsForm({ formFields, contactDetails }) {
  const { pathname } = useLocation();
  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
    pageUrl: "",
    ipAddress: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // formData.pageUrl = `https://www.xrealty.ae${pathname}`;
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      await axios.post(CONTACT_US, formData);
      // Show success toast
      // toast.success("Form submitted successfully!");

      // Show confirmation popup
      setIsSubmitted(true);

      // Hide the popup after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
      // Reset form data after successful submission
      setFormData(initialFormData);
      // Push custom event to dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Website Lead form submit",
      });

      ReactPixel.trackCustom("Website Lead form submit", {
        // Optional parameters
        page: pathname,
      });
    } catch (error) {
      // Show error toast
      toast.error(error.response?.data?.error + "");
      console.error(error.response?.data?.error);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-6">
      <div className="lg:col-span-3 lg:py-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <input
                className="w-full border border-gray-300 p-3 text-sm md:text-base"
                placeholder="First Name"
                type="text"
                id="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                className="w-full border border-gray-300 p-3 text-sm md:text-base"
                placeholder="Last Name"
                type="text"
                id="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <input
              className="w-full border border-gray-300 p-3 text-sm md:text-base"
              placeholder="Email"
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              className="w-full border border-gray-300 p-3 text-sm md:text-base"
              placeholder="Phone"
              type="tel"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <textarea
              className="w-full border border-gray-300 p-3 text-sm md:text-base"
              placeholder="Message"
              rows="4"
              id="message"
              value={formData.message}
              onChange={(e) => {
                if (e.target.value.length <= 500) {
                  handleChange(e);
                }
              }}
              maxLength="500"
            ></textarea>
            <p className="text-gray-400 text-sm md:text-base">
              {formData.message.length} of 500 max characters.
            </p>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="inline-block w-full bg-black px-5 py-3 font-medium text-white hover:text-black hover:bg-white hover:border-black hover:border"
            >
              Submit Enquiry
            </button>
          </div>
        </form>
      </div>
      <div className="lg:col-span-3 lg:py-12 gap-8 flex flex-col">
        {contactDetails.map((detail) => (
          <div key={detail.label} className="flex gap-6 items-start">
            <div className="flex-shrink-0">
              <a href={detail.action}>
                <img
                  className="w-[2rem] h-[2rem] lg:w-[4rem] lg:h-[4rem] mt-2"
                  src={detail.icon}
                  alt={detail.label}
                />
              </a>
            </div>
            <div>
              <p className=" text-sm md:text-base">{detail.label}</p>
              <p className=" text-gray-500">{detail.value}</p>
            </div>
          </div>
        ))}
      </div>

      {isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
            <h3 className="text-xl font-medium text-gray-800 mb-2">
              Form Submitted!
            </h3>
            <p className="text-gray-600">Thank you for submitting the form.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactUsForm;
