import React, { useState } from "react";

import axios from "axios";
import { CONTACT_US, CONTACT_US_CAREER, RESUME_UPLOAD } from "../api/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const setItemWithExpiry = (key, value, expiryInMilliseconds) => {
  const now = new Date();

  // Create an item with the value and the expiry time
  const item = {
    value: value,
    expiry: now.getTime() + expiryInMilliseconds,
  };

  // Save the item to localStorage
  localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // If the key does not exist, return null
  if (!itemStr) {
    return { isValid: false, value: null };
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // If the expiry time is in the past, remove the item and return false
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return { isValid: false, value: null };
  }

  // If the item is not expired, return true and the value
  return { isValid: true, value: item.value };
};

function ContactUsCareersPage({ formFields, contactDetails }) {
  const { pathname } = useLocation();
  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
    pageUrl: "",
    ipAddress: "",
    formId: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResumeUplaoded, setIsResumeUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [resumeSubmitted, setIsResumeSubmitted] = useState(
    getItemWithExpiry("resume_submitted").isValid &&
      getItemWithExpiry("resume_submitted").value === true
      ? true
      : false
  );

  const [formSubmitted, setIsFormSubmitted] = useState(
    getItemWithExpiry("form_submitted").isValid &&
      getItemWithExpiry("form_submitted").value === true
      ? true
      : false
  );

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!resumeSubmitted) {
      toast.info("Please upload your resume");
      return;
    }

    if (
      getItemWithExpiry("form_submitted").isValid &&
      getItemWithExpiry("form_submitted").value === true
    ) {
      toast.info("Form was already submitted. Try Again after 24 hours.");
      return;
    }
    try {
      // formData.pageUrl = `https://www.xrealty.ae${pathname}`;
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      formData.formId = getItemWithExpiry("form_id").value
        ? getItemWithExpiry("form_id").value
        : "";

      if (formData.formId === "") {
        toast.error(
          "Something Went Wrong. Please, Refresh the page and try again."
        );
        return;
      }
      await axios.post(CONTACT_US_CAREER, formData);
      // Show success toast
      // toast.success("Form submitted successfully!");

      // Show confirmation popup
      setIsSubmitted(true);

      setIsFormSubmitted(true);

      setItemWithExpiry("form_submitted", true, 24 * 60 * 60 * 1000);

      // Hide the popup after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
      // Reset form data after successful submission
      setFormData(initialFormData);
      // Push custom event to dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Career Page Lead form submit",
      });

      ReactPixel.trackCustom("Career Page Lead form submit", {
        // Optional parameters
        page: pathname,
      });
    } catch (error) {
      // Show error toast
      toast.error(error.response?.data?.error + "");
      console.error(error.response?.data?.error);
    }
  };

  const handleResumeUpload = async (e) => {
    e.preventDefault();

    const file = document.getElementById("upload_file").files[0];

    if (!file) {
      toast.error("Please Select a file");
      return;
    }

    if (resumeSubmitted) {
      toast.error(
        "A resume file was already submitted. Try Again after 24 hours."
      );
      return;
    }

    if (file.size / (1024 * 1024) > 5) {
      toast.error("File Size Larger Than 5 MB");
      return;
    }
    const formData = new FormData();
    formData.append("resume", file);
    setIsUploading(true); // Start uploading indicator

    const res = await axios.post(RESUME_UPLOAD, formData);
    if (res.status === 201) {
      // console.log(res);
      setItemWithExpiry("resume_submitted", true, 24 * 60 * 60 * 1000); // 1 day
      setItemWithExpiry("form_id", res?.data?.id, 60 * 1000); // 1 minute
      setIsResumeUploaded(true);
      setIsResumeSubmitted(true);
      setIsUploading(false); // Set the uploaded state
      // CLose the modal
      setTimeout(() => {
        setIsResumeUploaded(false);
      }, 500);
      // setFormData(initialFormData);
      return;
    }

    setIsUploading(false);
    toast.error("Something Went Wrong. Please, Try Again After Sometime");
    return;
  };

  return (
    <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-6">
      <div className="lg:col-span-3 lg:py-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <input
                className="w-full border border-gray-300 p-3 text-sm md:text-base"
                placeholder="First Name"
                type="text"
                id="firstname"
                required
                value={formData.firstname}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                className="w-full border border-gray-300 p-3 text-sm md:text-base"
                placeholder="Last Name"
                type="text"
                id="lastname"
                required
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <input
              className="w-full border border-gray-300 p-3 text-sm md:text-base"
              placeholder="Email"
              type="email"
              id="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              className="w-full border border-gray-300 p-3 text-sm md:text-base"
              placeholder="Phone"
              type="tel"
              id="phone"
              required
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <textarea
              className="w-full border border-gray-300 p-3 text-sm md:text-base"
              placeholder="Message"
              rows="4"
              id="message"
              value={formData.message}
              onChange={(e) => {
                if (e.target.value.length <= 500) {
                  handleChange(e);
                }
              }}
              maxLength="500"
            ></textarea>
            <p className="text-gray-400 text-sm md:text-base">
              {formData.message.length} of 500 max characters.
            </p>
          </div>
          <div>
            <label>
              Only PDF File Allowed, File Size Should Be Less than 5MB
            </label>
            <div className="flex flex-row">
              <input
                className="w-full border border-gray-300 p-1 text-sm md:text-base"
                placeholder="Upload File"
                type="file"
                id="upload_file"
                name="upload_file"
                accept="application/pdf"
                multiple={false}
                size={5000000}
              />

              <button
                // disabled={resumeSubmitted}
                type="submit"
                className={` inline-block w-[250px] bg-black px-5 font-medium text-white hover:text-black hover:bg-white hover:border-black hover:border ${
                  isUploading ? "cursor-wait" : ""
                }`}
                onClick={handleResumeUpload}
              >
                {isUploading
                  ? "Uploading..."
                  : isResumeUplaoded
                  ? "Uploaded"
                  : "Upload Resume"}
              </button>
            </div>
          </div>
          <div className="mt-4">
            <button
              // disabled={!resumeSubmitted || formSubmitted || !isResumeUplaoded}
              type="submit"
              className="inline-block w-full bg-black px-5 py-3 font-medium text-white hover:text-black hover:bg-white hover:border-black hover:border"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
      <div className="lg:col-span-3 lg:py-12 gap-8 flex flex-col">
        {contactDetails.map((detail) => (
          <div key={detail.label} className="flex gap-6 items-start">
            <div className="flex-shrink-0">
              <a href={detail.action}>
                <img
                  className="w-[2rem] h-[2rem] lg:w-[4rem] lg:h-[4rem] mt-2"
                  src={detail.icon}
                  alt={detail.label}
                />
              </a>
            </div>
            <div>
              <p className=" text-sm md:text-base">{detail.label}</p>
              <p className=" text-gray-500">{detail.value}</p>
            </div>
          </div>
        ))}
      </div>

      {isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
            <h3 className="text-xl font-medium text-gray-800 mb-2">
              Form Submitted!
            </h3>
            <p className="text-gray-600">Thank you for submitting the form.</p>
          </div>
        </div>
      )}

      {isResumeUplaoded && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
            <h3 className="text-xl font-medium text-gray-800 mb-2">
              File Upload Successfully!
            </h3>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactUsCareersPage;
