import React from "react";
import { FaWhatsapp, FaYoutube } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from "react-router-dom";

const InfoPanel = ({ agentInfo, url }) => {
  const contactButtons = [
    {
      icon: <AiOutlinePhone />,
      label: "Call",
      color: "bg-white",
      action: `tel:${agentInfo?.phone}`,
    },
    {
      icon: <MdOutlineMailOutline />,
      label: "Email",
      color: "bg-white",
      action: `mailto:${agentInfo?.email}`,
    },
    {
      icon: <FaWhatsapp />,
      label: "WhatsApp",
      color: "bg-white",
      action: `https://wa.me/${agentInfo?.phone}`,
    },
  ];

  const details = [
    agentInfo?.experience > 0 && {
      label: "Experience",
      value: `${agentInfo?.experience} years`,
    },
    {
      label: "Specialization",
      value: agentInfo?.specialties?.join(", "),
    },
    { label: "Languages", value: agentInfo?.languages?.join(", ") },
  ].filter(Boolean);

  return (
    <div className="w-full z-10 h-[100vh] lg:h-auto backdrop-blur-sm overflow-y-auto">
      <div className="grid grid-cols-5 gap-2 bg-transparent text-white">
        {/* Image Section */}
        <div className="col-span-5 lg:col-span-2 relative group items-center justify-center flex">
          <Link
            reloadDocument
            to={`/agent/${agentInfo?.name_slug}/`}
            className="overflow-hidden shadow-2xl relative h-full"
          >
            <img
              src={agentInfo?.profile_picture}
              alt={agentInfo?.name}
              className="w-full h-full object-cover object-top"
            />
          </Link>
        </div>

        {/* Details Section */}
        <div className="col-span-5 lg:col-span-3 flex flex-col justify-center backdrop-blur-sm bg-black opacity-60 p-6 shadow-xl">
          <div className="space-y-6">
            <h2 className="font-bold text-base md:text-2xl lg:text-xl xl:text-2xl text-white line-clamp-2">
              {agentInfo?.name}
              <p className="text-base md:text-xl lg:text-lg xl:text-base text-gray-200">
                {agentInfo?.education}
              </p>
            </h2>
            <div className="space-y-4">
              {details.map((item, index) => (
                <div key={index} className="flex items-start gap-2">
                  <span className="font-semibold text-white md:text-lg lg:text-xs xl:text-lg">
                    {item.label}
                  </span>
                  <span className="text-gray-200 md:text-lg lg:text-xs xl:text-lg line-clamp-1">
                    {item.value}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Contact Buttons */}
          <div className="grid grid-cols-3 gap-4 xl:gap-6 2xl:gap-4 mt-8">
            {contactButtons.map((button, index) => (
              <a
                key={index}
                href={button.action}
                target={button.label === "WhatsApp" ? "_blank" : "_self"}
                rel="noopener noreferrer"
                className={`group ${button.color} text-black py-2 px-3 hover:bg-black hover:text-white transition-all duration-300 focus:outline-none flex items-center justify-center text-sm shadow-lg border border-white`}
              >
                <span className="text-lg md:text-2xl lg:text-lg">
                  {button.icon}
                </span>
              </a>
            ))}
          </div>
          <a
            href={url}
            target="_blank"
            className="hidden lg:flex justify-center items-center mt-10 border p-2"
            rel="noreferrer"
          >
            <span className="text-base">Watch more videos on Youtube</span>
            <FaYoutube className="h-6 w-16" />
          </a>
        </div>
      </div>

      <div className="mt-1 backdrop-blur-sm bg-black opacity-60 px-7 py-6 shadow-xl text-white lg:h-[13vh] xl:h-[30vh] 2xl:h-[67vh] overflow-y-auto gap-4 lg:grid lg:grid-cols-2 h-full flex flex-col p-4">
        <div className="col-span-2 h-[50vh] overflow-y-auto">
          <h3 className="font-bold mb-2 text-xl md:text-2xl lg:text-xl">
            Professional Snapshot
          </h3>
          <p className="text-gray-200 text-base  md:text-xl lg:text-base leading-relaxed">
            {agentInfo?.bio}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
