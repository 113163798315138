import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import black_logo from "../assets/xr_logo_black.png";
import axios from "axios";
import { ALL_COMMUNITIES } from "../api/constants";
import ItemsPerPage from "./ItemsPerPage";
import Pagination from "./Pagination";
import { Helmet } from "react-helmet";

function AllCommunities({ imagesWithInfo, viewAll }) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(8);

  const communityRef = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    if (communityRef.current) {
      communityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(ALL_COMMUNITIES, {
          params: { page: currentPage, limit },
        });
        setData(response.data.communities);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, limit]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1); // Reset to first page when limit changes
  };

  const finalImagesWithInfo = imagesWithInfo || data;

  let UpdatedImagesWithInfo = finalImagesWithInfo;

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div
        className="flex flex-col gap-10 lg:col-span-6  md:py-3 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] py-8 lg:py-16"
        ref={communityRef}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">Communities</h2>
          <ItemsPerPage
            limit={limit}
            onLimitChange={handleLimitChange}
            options={[8, 16, 24, 32, 64]}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {UpdatedImagesWithInfo?.map((imageWithInfo, index) => (
            <div
              key={index}
              className="relative h-auto flex flex-col  border border-gray-300 overflow-visible"
            >
              <Link reloadDocument to={`/area/${imageWithInfo.slug}`}>
                <img
                  alt=""
                  src={imageWithInfo?.images[0]?.url}
                  className="aspect-video w-full object-cover"
                />
              </Link>

              <img
                alt="Clip"
                src={black_logo}
                className="absolute -top-4 right-4 h-9 w-9 bg-white"
              />
              <div className="p-4 overflow-hidden flex justify-center items-center h-full">
                <dl>
                  <div>
                    <dt className="sr-only">Title</dt>
                    <dd className="text-sm md:text-xl  text-black  text-center">
                      {imageWithInfo?.name?.toUpperCase()}
                    </dd>
                  </div>
                  {/* <div>
                    <dt className="sr-only">Title</dt>
                    <dd className="text-sm md:text-base  text-black  text-center">
                      {imageWithInfo?.developer}
                    </dd>
                  </div> */}
                </dl>

                {/* <div className="mt-6 items-center lg:gap-2 gap-4 sm:gap-1">
                  <div className="sm:shrink-0 sm:items-center sm:gap-x-8 sm:gap-y-2  md:text-base lg:text-xl  text-gray-500  flex-wrap flex gap-1">
                    {imageWithInfo?.type[0]?.bedrooms && (
                      <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-sm md:text-base">
                        <div>{icons[0]}</div>
                        <p className="font-light">
                          {imageWithInfo?.type[0]?.bedrooms}
                        </p>
                      </div>
                    )}
                    {imageWithInfo?.developer && (
                      <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-sm md:text-base">
                        <div>{icons[1]}</div>
                        <p className="font-light">{imageWithInfo?.developer}</p>
                      </div>
                    )}
                    {imageWithInfo?.community_name && (
                      <div className="mt-1.5 sm:mt-0 flex items-center gap-x-1 text-sm md:text-base">
                        <div>{icons[2]}</div>
                        <p className="font-light">
                          {imageWithInfo?.community_name?.toLowerCase()}
                        </p>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default AllCommunities;
