import React from "react";
import { Helmet } from "react-helmet";
import { BASE_URL, WEB_URL } from "../api/constants";
import { useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const { pathname } = useLocation();
  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>Privacy Policy - Xperience​ Realty | Real Estate Dubai</title>
        <meta name="description" content="Privacy Policy and Cookies" />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href={WEB_URL + pathname.slice(1)}></link>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Privacy Policy - Xperience Realty | Real Estate Dubai"
        ></meta>
        <meta
          property="og:description"
          content="Privacy Policy and Cookies"
        ></meta>
        <meta
          property="og:site_name"
          content="Xperience Realty | Real Estate Dubai"
        ></meta>
        <meta property="og:url" content={WEB_URL + pathname.slice(1)}></meta>
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png"
        ></meta>
        <meta
          property="og:image:secure_url"
          content="https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png"
        ></meta>
        <meta
          property="og:image:alt"
          content="Xperience Realty Real Estate Dubai"
        ></meta>
        <meta property="og:image:type" content="image/jpeg"></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "PrivacyPolicy",
            name: "Privacy Policy | Xperience Realty | Real Estate Dubai",
            description:
              "Learn about Xperience Realty's commitment to your privacy and how we handle your personal information when engaging with our comprehensive real estate services in Dubai.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script> */}
      </Helmet>
      <div className="mb-auto  bg-white w-full">
        <h1 className="text-2xl md:text-3xl font-bold mb-2 pt-3  text-center">
          Privacy Policy
        </h1>
        <div className="shadow-lg  bg-white p-10">
          <h1 className="text-2xl font-bold mb-4 ">
            Xperience Realty Privacy & Cookies Policy
          </h1>
          <p className="mb-4">
            Xperience Realty Real Estate LLC is dedicated to safeguarding the
            privacy of our website users. This privacy statement outlines our
            practices in this regard. The primary objective of this statement is
            to provide you with information on:
          </p>{" "}
          <p className="mb-4">
            The types of information we gather from individuals who visit the
            Xperience Realty Real Estate website, located at{" "}
          </p>
          <h2 className="text-2xl font-semibold mb-2">What do we offer?</h2>
          <p className="mb-4">
            Xperience Realty, a leading real estate agency, is the ultimate
            choice for investment in Dubai real estate. Our comprehensive
            services, as outlined in the website’s privacy policy, include:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Property management</li>
            <li>Property listings</li>
            <li>Real estate sales</li>
            <li>Property advertising arrangements</li>
            <li>Identifying the qualified buyers</li>
            <li>Property valuation</li>
            <li>Competitive market analysis</li>
            <li>Marketing and advertising services</li>
            <li>Negotiation expertise</li>
            <li>Tenancy contract management</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-2">
            What data do we collect?
          </h2>
          <p className="mb-4">
            Xperience Realty gathers the following information:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Curriculum vitae (CV)</li>
            <li>
              Details of participation in recruitment events (including date and
              location)
            </li>
          </ul>
          <h2 className="text-2xl font-semibold mb-2">
            How do we collect your data?
          </h2>
          <p className="mb-4">
            When you register for services on any website affiliated with
            Xperience Realty, which includes but is not limited to services
            related to mortgages, rentals, sales, sales progression, property
            management, commercial property, and competitions, we may request
            personal information from you. This personal information may
            encompass details like your name, email address, postal address,
            phone number, or date of birth.
          </p>
          <p className="mb-4">
            Different web pages may request various types of personal
            information. By entering your details in the provided fields, you
            allow Xperience Realty and its service providers to offer you the
            selected services. It’s important to note that some web pages may
            require you to provide additional personal information, and in
            certain instances, this information may be of a sensitive nature.
            Whenever we request such information, we will provide clear
            explanations on these web pages regarding why we are collecting your
            information and how we intend to use it. Additionally, Xperience
            Realty utilizes cookies and collects IP addresses (an IP address is
            a unique number that can identify a specific computer or other
            network devices on the internet). We employ analytical software to
            examine IP addresses and cookies with the goal of improving your
            user experience. It’s worth noting that this information is not
            utilized to create a personal profile of you, and the log files are
            regularly cleared.
          </p>
          <h2 className="text-2xl font-semibold mb-2">
            How will we use your data?
          </h2>
          <p className="mb-4">
            Xperience Realty collects your data for the following purposes:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>To process your requests and manage your account.</li>
            <li>
              To send you emails containing special offers for other products
              and services that we believe may interest you.
            </li>
            <li>
              To enhance the functionality of any service you have subscribed
              to, ensuring that Xperience Realty can provide you with the best
              possible service.
            </li>
            <li>
              To invite you to voluntarily participate in surveys related to
              Xperience Realty services.
            </li>
            <li>
              For marketing purposes, but only in cases where you have
              explicitly given your consent.
            </li>
          </ul>
          <p className="mb-4">
            Please refer to local pages for detailed information on how
            Xperience Realty will contact you regarding specific services.
          </p>
          <p className="mb-4">
            Xperience Realty is committed to maintaining the confidentiality of
            your information, except when disclosure is mandated or permitted by
            law, such as for government agencies and law enforcement. We will
            only use your information within Xperience Realty, but we may employ
            third parties to process your data. Xperience Realty requires these
            third parties to strictly adhere to our instructions and ensures
            they do not use any personal information for their own business
            purposes.
          </p>
          <h2 className="text-2xl font-semibold mb-2">
            What are your data protection rights?
          </h2>
          <p className="mb-4">
            Xperience Realty wants to ensure that you are fully informed about
            your data protection rights. As a user, you have the following
            entitlements:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              The right to access – You can request Xperience Realty to provide
              you with copies of your personal data. Please note that a small
              fee may be charged for this service.
            </li>
            <li>
              The right to rectification – You have the right to ask Xperience
              Realty to correct any information you believe is inaccurate. You
              also have the right to request Xperience Realty to supplement any
              information you consider incomplete.
            </li>
            <li>
              The right to deletion – You can request Xperience Realty to delete
              your personal data, subject to certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to ask
              Xperience Realty to limit the processing of your personal data,
              subject to certain conditions.
            </li>
            <li>
              The right to object to data processing – You can object to
              Xperience Realty’s processing of your personal data, subject to
              certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              Xperience Realty transfer the data we have collected to another
              organization or directly to you, under specific conditions.
            </li>
          </ul>
          <p className="mb-4">
            Upon making a request, Xperience Realty has one month to respond to
            you. If you wish to exercise any of these rights, please contact us
            via the following means:
          </p>
          <p className="mb-4">
            Phone:{" "}
            <a
              href="tel:+971564080888"
              className="text-blue-500 hover:underline"
            >
              +971 56 408 0888
            </a>{" "}
            or{" "}
            <a
              href="tel:+97147049400"
              className="text-blue-500 hover:underline"
            >
              +971 47 04 9400
            </a>
          </p>
          <p className="mb-4">
            Or write us at:{" "}
            <a
              href="mailto:info@xrealty.ae"
              className="text-blue-500 hover:underline"
            >
              info@xrealty.ae
            </a>
          </p>
          <h2 className="text-2xl font-semibold mb-2">
            How do we use cookies?
          </h2>
          <p className="mb-4">
            Xperience Realty, like other websites, uses cookies, including those
            placed and read by third-party providers, for purposes that enhance
            your experience. Cookies are small files that collect data and store
            information on your device. These cookies help us identify account
            holders, track new users, record session information, keep you
            signed in, understand how you use our website for advertising
            purposes, and optimize the app’s functionality.
          </p>
          <p className="mb-4">
            Most mobile devices accept cookies automatically. However, if you
            prefer, you can disable cookies through your device settings. Please
            note that certain features and functions within Xperience Realty may
            not work as intended if you choose to disable cookies.
          </p>
          <h2 className="text-2xl font-semibold mb-2">
            What types of cookies do we use?
          </h2>
          <p className="mb-4">
            There are various cookie types, but our website primarily employs
            two categories:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Functionality cookies</strong> – Xperience Realty utilizes
              these cookies to recognize you on our website and retain your
              previously chosen preferences. This may encompass language
              preferences and location. Both first-party and third-party cookies
              are utilized.
            </li>
            <li>
              <strong>Advertising cookies</strong> – Xperience Realty uses these
              cookies to gather data about your website visit, such as the
              content you accessed, the links you followed, and details about
              your browser, device, and IP address. Some limited aspects of this
              data may be shared with third parties for advertising purposes. We
              may also share online data collected through cookies with our
              advertising partners. As a result, when you visit another website,
              you may encounter ads tailored to your browsing behavior on our
              site.
            </li>
          </ul>
          <h2 className="text-2xl font-semibold mb-2">
            Privacy policies of other websites
          </h2>
          <p className="mb-4">
            The Xperience Realty website contains links to other websites. Our
            privacy policy applies only to our website, so if you click on a
            link to another website, you should read their privacy policy.
          </p>
          <h2 className="text-2xl font-semibold mb-2">
            Changes to our privacy policy
          </h2>
          <p className="mb-4">
            Xperience Realty reviews its privacy policy from time to time and
            posts its revisions on this webpage.
          </p>
          <h2 className="text-2xl font-semibold mb-2">How to contact us</h2>
          <p className="mb-4">
            If you have inquiries regarding Xperience Realty’s privacy policy,
            the data we have on record for you, or if you wish to exercise any
            of your data protection rights, please feel free to get in touch
            with us.
          </p>
          <p className="mb-4">
            Phone:{" "}
            <a
              href="tel:+971564080888"
              className="text-black font-bold hover:underline"
            >
              +971 56 408 0888
            </a>{" "}
            or{" "}
            <a
              href="tel:+97147049400"
              className="text-black font-bold hover:underline"
            >
              +971 47 04 9400
            </a>
          </p>
          <p className="mb-4">
            Or write us at:{" "}
            <a
              href="mailto:info@xrealty.ae"
              className="text-black font-bold hover:underline"
            >
              info@xrealty.ae
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
