/* eslint-disable jsx-a11y/iframe-has-title */
// src/components/ProjectDetails.js
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import XploreDubaiImages from "./XploreDubaiImages";
import Slider from "react-slick";
import { FaKey, FaMap, FaMoneyBillWave } from "react-icons/fa";
import { PROPERTY } from "../api/constants";
import axios from "axios";
import LetXpertsInput from "./LetXpertsInput";
import { Helmet } from "react-helmet";
import NotFound from "./NotFound";
import "./StarEmployeesImages.css"; // Import your custom CSS

import ImageSlider from "./ImageSlider/ImageSlider";

function ProjectDetails() {
  const scrollToRef = useRef(null);
  const [data, setData] = useState();

  const [noData, setNoData] = useState(false);

  const handleScrollTo = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { title } = useParams();

  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(PROPERTY + `/${title}`);

        if (response.data.success) {
          setData(response?.data?.property);
        } else {
          setNoData(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [title]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      {noData ? (
        <NotFound />
      ) : (
        <>
          <Helmet>
            <link
              rel="alternate"
              hreflang="en-ae"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            <link
              rel="alternate"
              hreflang="en-us"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            <link
              rel="alternate"
              hreflang="en-gb"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            <link
              rel="alternate"
              hreflang="en-sg"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            <link
              rel="alternate"
              hreflang="en-in"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            <link
              rel="alternate"
              hreflang="en"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            <link
              rel="alternate"
              hreflang="x-default"
              href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
            />
            {/* <title>{data?.property_name}</title> */}
            <script type="application/ld+json">
              {JSON.stringify(data?.schema_org?.properties)}
            </script>
          </Helmet>
          {/* <ScrollTriggerModal triggerPoints={[0.3, 0.6]}>
            <LetXpertsInput theme="light" />
          </ScrollTriggerModal> */}
          <Slider className="overflow-hidden" {...settings}>
            {data?.gallery1?.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full aspect-video lg:h-[80dvh] object-cover"
                />
              </div>
            ))}
          </Slider>

          <div className="lg:py-16  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-8 lg:gap-20">
            <div className="flex flex-col items-center gap-8">
              <div className="mx-auto py-8 sm:py-12 lg:m-0 lg:p-0 ">
                <div
                  className={`grid gap-8 md:gap-2 lg:gap-8  md:grid-cols-5 items-center`}
                >
                  <div className="lg:py-24  md:col-span-4  gap-2 flex flex-col">
                    <span className="flex items-center">
                      <span className="pr-6 text-gray-400 text-sm md:text-base">
                        {data?.section_1?.heading.toUpperCase()}
                      </span>
                      <span className="h-px  w-32 bg-black hidden lg:flex"></span>
                    </span>
                    <h1 className="text-base md:text-2xl">
                      {data?.section_1?.title.toUpperCase()}
                    </h1>
                    <p className="lg:mt-4 text-sm md:text-base font-extralight leading-5 text-gray-600">
                      {data?.section_1?.description}
                    </p>
                  </div>

                  <div
                    className={`relative overflow-hidden h-auto  order-last flex flex-col w-full justify-center  gap-2 px-14 md:p-2 lg:p-2 xl:p-10 2xl:p-14 md:h-full md:justify-end lg:justify-center`}
                  >
                    <img
                      alt=""
                      src={data?.section_1?.image}
                      className=" object-contain   "
                    />
                    <button
                      className="w-full outline-none border border-black p-2 bg-black text-white  hover:bg-white hover:text-black flex items-center justify-center lg:text-sm "
                      onClick={handleScrollTo}
                    >
                      CONTACT US
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-8 lg:py-16  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col  bg-gray-100">
            <XploreDubaiImages sections={data?.images} projectImages="true" />
          </div>

          <div className="py-16 lg:py-16  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-8 lg:gap-20">
            <div className="flex flex-col gap-2 lg:gap-2">
              <span className="flex items-center">
                <span className="lg:pr-6 text-gray-400 text-sm md:text-base">
                  {data?.about_project?.heading.toUpperCase()}
                </span>
                <span className="h-px w-32 bg-gray-300 "></span>
              </span>
              <h2 className="text-base md:text-2xl">
                {data?.about_project?.title.toUpperCase()}
              </h2>
              <p className="lg:mt-4 text-sm md:text-base font-extralight leading-5 text-gray-600">
                {data?.about_project?.description}
              </p>
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 mt-4">
                <div className="h-22 2xl:h-32 border border-gray-300 flex items-center p-4 gap-4">
                  <div className="flex-shrink-0">
                    <FaMap className="h-6 w-6 md::w-8 md:h-8 " />
                  </div>
                  <p className="text-base md:text-2xl lg:text-lg xl:text-xl 2xl:text-2xl  ">
                    STARTING PRICE {data?.price}
                  </p>
                </div>
                {data?.type?.map((type, index) => {
                  return (
                    <div
                      key={index}
                      className="h-22 lg:h-32 border border-gray-300 flex items-center p-4 gap-4"
                    >
                      <div className="flex-shrink-0">
                        <FaMap className="h-6 w-6 md::w-8 md:h-8" />
                      </div>
                      <p className="text-base md:text-2xl lg:text-lg xl:text-xl 2xl:text-2xl ">
                        {type.bedrooms} BR {type.name.toUpperCase()}
                      </p>
                    </div>
                  );
                })}
                {/* <div className="h-22 border border-gray-300 flex items-center p-6 gap-4">
              <div className="flex-shrink-0">
                <FaMap className="w-8 h-8" />
              </div>
              <p className="text-xl md:text-2xl">
                {data?.type[0]?.bedrooms} {data?.type[0]?.name.toUpperCase()}
              </p>
            </div> */}
                {data?.features?.year_built && (
                  <div className="h-22 lg:h-32 border border-gray-300 flex items-center p-4 gap-4">
                    <div className="flex-shrink-0">
                      <FaKey className="h-6 w-6 md:w-8 md:h-8" />
                    </div>
                    <p className="text-base md:text-2xl">
                      HANDOVER {data?.features?.year_built}
                    </p>
                  </div>
                )}

                {data?.payment_plan && data?.payment_plan.trim() !== "" && (
                  <div className="h-22 lg:h-32 border border-gray-300 flex items-center p-4 gap-4">
                    <div className="flex-shrink-0">
                      <FaMoneyBillWave className="h-6 w-6 md:w-8 md:h-8" />
                    </div>
                    <p className="text-base md:text-2xl">
                      {data?.payment_plan.toUpperCase()}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="py-8 lg:py-16  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-8 lg:gap-20 bg-gray-100">
            <div>
              <span className="flex items-center mb-8">
                <span className="pr-6 text-gray-400 text-base md:text-2xl">
                  GALLERY
                </span>
                <span className="h-px w-32 bg-gray-300 "></span>
              </span>
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-4 4xl:gap-0">
                {/* First Cell */}
                {data?.gallery1?.length > 0 && (
                  <div className="h-full">
                    <h2 className="text-base md:text-lg mb-3 lg:mb-7 text-center">
                      {data?.gallery_title_1}
                    </h2>
                    {/* <Slider className="overflow-hidden h-full" {...settings}>
                      {data?.gallery1?.map((image, index) => (
                        <div key={index} className=" h-full">
                          <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-full"
                          />
                        </div>
                      ))}
                    </Slider> */}
                    <ImageSlider images={data?.gallery1} />
                    {data?.gallery_description_1 &&
                    data?.gallery_description_1?.trim() !== "" ? (
                      <p className="text-sm md:text-base font-thin mb-4 text-center mt-3 lg:mt-7 w-3/4 mx-auto">
                        {data?.gallery_description_1}
                      </p>
                    ) : null}
                  </div>
                )}

                {/* Second Cell */}
                {data?.gallery2?.length > 0 && (
                  <div>
                    <h2 className="text-base md:text-lg mb-3 lg:mb-7 text-center">
                      {data?.gallery_title_2}
                    </h2>
                    {/* <Slider className="overflow-hidden" {...settings}>
                      {data?.gallery2?.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="aspect-video object-cover"
                          />
                        </div>
                      ))}
                    </Slider> */}
                    <ImageSlider images={data?.gallery2} />
                    {data?.gallery_description_2 &&
                      data?.gallery_description_2?.trim() !== "" && (
                        <p className="text-sm md:text-base font-thin mb-4 text-center mt-3 lg:mt-7 w-3/4 mx-auto">
                          {data?.gallery_description_2}
                        </p>
                      )}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-8">
              <span className="flex items-center">
                <span className="pr-6 text-gray-400 text-base md:text-2xl mb-4">
                  AMENITIES
                </span>
                <span className="h-px w-32 bg-gray-300 "></span>
              </span>
              <p className="text-sm md:text-base font-thin mb-10">
                {data?.amenities?.description}
              </p>
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-8">
                {data?.amenities?.icons?.map((icon, index) => {
                  return (
                    <div
                      key={index}
                      className="lg:h-32  flex flex-col justify-center
             items-center gap-2 lg:gap-4"
                    >
                      <img
                        className="w-8 h-8 md:h-10 md:w-10 lg:w-12 lg:h-12"
                        src={icon?.icon_url}
                        alt={icon?.icon_text}
                      />
                      <p className="text-sm md:text-base text-center">
                        {icon?.icon_text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <section className="">
            <iframe
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={`https://maps.google.com/maps?q=${data?.location?.coordinates?.lat},${data?.location?.coordinates?.lng}&hl=en&z=14&output=embed`}
            >
              <a href="https://www.gps.ie/">gps devices</a>
            </iframe>
          </section>

          <div
            className="py-16 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[20%] flex flex-col gap-20"
            ref={scrollToRef}
          >
            <div className="grid lg:grid-cols-2 gap-16">
              <div className="flow-root order-2">
                <h2 className="text-base md:text-2xl lg:mb-2">FAQ'S</h2>
                <div className="divide-y divide-gray-100">
                  {data?.faqs.map((faq, index) => (
                    <details
                      key={index}
                      className="group py-4 lg:py-8 [&_summary::-webkit-details-marker]:hidden"
                    >
                      <summary className="flex cursor-pointer items-center justify-between text-gray-900">
                        <h2 className=" text-sm md:text-base font-bold">
                          {faq.question}
                        </h2>
                        <span className="relative size-5 shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute inset-0 size-5 opacity-100 group-open:opacity-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute inset-0 size-5 opacity-0 group-open:opacity-100"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </summary>
                      <p className="mt-4 leading-relaxed text-gray-70  text-sm md:text-base">
                        {faq.answer}
                      </p>
                    </details>
                  ))}
                </div>
              </div>
              <div>
                <h2 className="text-base md:text-2xl lg:mb-2">
                  OUR XPERT WILL HELP YOU
                </h2>

                <LetXpertsInput theme="light" property={true} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProjectDetails;
