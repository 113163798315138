import React, { useEffect, useRef, useState } from "react";
import FormatWidget from "./FormatWidget";
import axios from "axios";
import { BASE_URL, FETCH_PROPERTY_TYPES } from "../api/constants";
import ItemsPerPage from "./ItemsPerPage";
import Pagination from "./Pagination";
import ProjectImagesOld from "./ProjectImagesOld";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function AllProjects() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageHeading, setPageHeading] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [limit, setLimit] = useState(32);
  const [currenUrl, setCurrentUrl] = useState(useLocation().pathname);
  const [filters, setFilters] = useState();

  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  const propertyRef = useRef(null);

  useEffect(() => {
    if (
      currenUrl.split("/")[1] === "dubai-properties" &&
      !currenUrl.split("/")[2]
    ) {
      localStorage.setItem("activeFilter", "dubai-properties");
    }
  }, [currenUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(FETCH_PROPERTY_TYPES);
        setFilters(response.data.propertyTypes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (propertyRef.current) {
      propertyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    const fetchData = async () => {
      try {
        // const response = await axios.get(`${BASE_URL}dubai-properties`, {
        //   params: { page: currentPage, limit },
        // });

        // const response = await axios.get(
        //   `${BASE_URL}${
        //     currenUrl.split("/")[1] + "/" + currenUrl.split("/")[2]
        //   }`,
        //   {
        //     params: { page: currentPage, limit },
        //   }
        // );

        const response = await axios.get(
          `${BASE_URL}${
            currenUrl.split("/")[1] + "/" + currenUrl.split("/")[2]
          }`
        );

        setData(response.data.properties);
        setPageHeading(response.data.pageHeading);
        setPageDescription(response.data.pageDescription);
        // setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Update the URL to reflect the current page and limit
    // const params = new URLSearchParams(search);
    // params.set("page", currentPage); // Update the 'page' query param
    // // params.set("limit", limit);

    // const pageLimit = parseInt(params.get("limit"), 10) || 32;
    // setLimit(pageLimit);
    // navigate(`${pathname}?${params.toString()}`, { replace: true });
  }, [currenUrl]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handleLimitChange = (newLimit) => {
  //   setLimit(newLimit);
  //   setCurrentPage(1); // Reset to first page when limit changes
  // };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    // Update the limit and reset current page to 1
    const newUrl = `${pathname}?page=${1}&limit=${newLimit}`;
    window.location.href = newUrl; // Navigate and reload
  };

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="pb-4 px-4" ref={propertyRef}>
        <FormatWidget>
          <div className="flex flex-wrap gap-2 py-4">
            <Link
              reloadDocument
              to={`/dubai-properties/`}
              onClick={() => {
                localStorage.setItem("activeFilter", "dubai-properties");
              }}
              className={`px-2 py-1 text-xs md:text-sm flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white cursor-pointer
                    ${
                      localStorage.getItem("activeFilter") ===
                      currenUrl.split("/")[1]
                        ? "bg-black text-white border-black"
                        : "bg-white text-black border-black hover:bg-black hover:text-white"
                    }`}
            >
              VIEW ALL
            </Link>
            {filters?.map((filter) => (
              <Link
                key={filter.name_slug}
                reloadDocument
                to={`/dubai-properties/${filter.page_slug}/`}
                onClick={() => {
                  localStorage.setItem("activeFilter", filter.page_slug);
                }}
                className={`px-2 py-1 text-xs md:text-sm flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white cursor-pointer
                    ${
                      filter.page_slug === currenUrl.split("/")[2]
                        ? "bg-black text-white border-black"
                        : "bg-white text-black border-black hover:bg-black hover:text-white"
                    }`}
              >
                {filter.name.toUpperCase()}
              </Link>
            ))}
          </div>
        </FormatWidget>
        <FormatWidget
          title={
            pageHeading.trim() !== ""
              ? pageHeading
              : "Off-plan Projects For Sale in Dubai"
          }
        >
          <div
            className="text-justify leading-5 text-xs md:text-sm font-extralight text-gray-500"
            dangerouslySetInnerHTML={{
              __html:
                pageDescription?.trim() !== ""
                  ? pageDescription
                  : "Looking for a brand new place to call home in Dubai? Xperience Realty has you covered. Explore our collection of new developments, filter by your preferences, or give us a call to discuss your dream property.",
            }}
          ></div>
          <div>
            {/* <ItemsPerPage
              limit={limit}
              onLimitChange={handleLimitChange}
              options={[8, 16, 24, 32, 64]}
            /> */}
          </div>
          <ProjectImagesOld imagesWithInfo={data} viewAll="true" />
        </FormatWidget>

        {/* <Pagination
          pagePath={pathname}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          setCurrentPage={setCurrentPage}
        /> */}
      </div>
    </>
  );
}

export default AllProjects;
