import React, { useState } from "react";
import CommunityImages from "./CommunityImages";
import FormatWidget from "./FormatWidget";

export default function XploreCommunities({ communities }) {
  return (
    <FormatWidget>
      <div>
        <h3 className="font-bold text-base md:text-lg ">XPLORE COMMUNITIES</h3>
      </div>

      {/* ProjectImages component */}
      <CommunityImages imagesWithInfo={communities} />
    </FormatWidget>
  );
}
