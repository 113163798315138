import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./StarEmployeesImages.css"; // Import your custom CSS

function OurPartners() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //     "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445593/xr_media/vfnqx9zkc7tio5mjfubv.jpg",

  const imageUrls = [
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722858422/xr_media/hukbynue5yh3nq3oy7se.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445624/xr_media/bdgd6g378gffktf6hzfd.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445533/xr_media/jgv4q4oixikn7hytciv4.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445696/xr_media/sdv6w1ppiaxssfwfm2wr.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445725/xr_media/fixj1qygzcoxlcohwp0u.jpg",
    "https://res.cloudinary.com/dkhns25jh/image/upload/v1722445770/xr_media/dv2zlbdy0g9ofavfdpt7.png",
    // "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/v1727270320/xr_media/lgy01b4atrfm0kv11otw.png",
  ];

  const slides = imageUrls.map((url, index) => (
    <div
      key={index}
      className="px-2 md:h-15 lg:h-20 xl:h-24  4xl:object-contain object-fill"
    >
      <img
        src={url}
        alt={`Slide ${index + 1}`}
        className="xl:h-[100px] 4xl:h-[100px]"
      />
    </div>
  ));

  return (
    <div>
      <div className="flex items-center justify-between ">
        <div className="flex flex-col mr-2">
          <h3 className="font-semibold text-base md:text-lg">
            OUR SIGNATURE PARTNER
          </h3>
        </div>
      </div>
      <div className="pt-4">
        <Slider {...settings}>{slides}</Slider>
      </div>
    </div>
  );
}

export default OurPartners;
