import React, { useEffect } from "react";

const Pagination = ({
  pagePath,
  currentPage,
  totalPages,
  limit,
  setCurrentPage,
}) => {
  // Sync state with the `page` query parameter in the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1; // Default to page 1 if no query param

    setCurrentPage(page);
  }, []);

  // Function to update the page
  // const handlePageChange = (page) => {
  //   if (page >= 1 && page <= totalPages) {
  //     // Update the URL with the new page number
  //     const params = new URLSearchParams(location.search);
  //     params.set("page", page); // Update the 'page' query param
  //     params.set("limit", limit); // Ensure the limit is consistent
  //     navigate(`${location.pathname}?${params.toString()}`);

  //     // Call the onPageChange function
  //     onPageChange(page);
  //   }
  // };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      const newUrl = `${pagePath}?page=${page}&limit=${limit}`;
      window.location.href = newUrl; // Navigate and reload
    }
  };

  return (
    <div className="flex flex-wrap justify-center my-8 space-x-2">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className={`px-4 py-2 mb-2 border ${
          currentPage === 1
            ? "cursor-not-allowed bg-gray-200"
            : "bg-white text-black"
        }`}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          className={`px-4 py-2 mb-2 border ${
            currentPage === index + 1
              ? "bg-black text-white"
              : "bg-white text-black"
          }`}
        >
          {index + 1}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className={`px-4 py-2 mb-2 border ${
          currentPage === totalPages
            ? "cursor-not-allowed bg-gray-200"
            : "bg-white text-black"
        }`}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
