import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarEmployeesImages from "./StarEmployeesImages";
import FormatWidget from "./FormatWidget";

function StarEmployees(props) {
  return (
    <FormatWidget
      title="MEET OUR XPERIENCE STARS"
      subtitle="In Dubai, our experts offer customers diverse exploration options."
      linkText="VIEW ALL"
      linkTo="/agent/"
      meetXR={true}
    >
      <StarEmployeesImages data={props?.data} />
    </FormatWidget>
  );
}

export default StarEmployees;
