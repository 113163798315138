import React, { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import StarEmployeesImages from "./StarEmployeesImages";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { AGENT, BASE_URL } from "../api/constants";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import ScrollTriggerModal from "./ScrollTriggerModal";
import LetXpertsInput from "./LetXpertsInput";
import TwitterIcon from "../assets/twitter.png";
import InstagramIcon from "../assets/instagram.png";
import FacebookIcon from "../assets/facebook.png";
import LinkedInIcon from "../assets/linkedin.png";
import YouTubeIcon from "../assets/youtube.png";

function AgentDetails({ agentInfo, imagesWithInfo }) {
  const { agentName } = useParams();

  const [agent, setAgent] = useState();
  const [moreOfTheTeam, setMoreOfTheTeam] = useState();

  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(AGENT + `${agentName}`);
        setAgent(response?.data?.agent);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [agentName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(BASE_URL);
        setMoreOfTheTeam(response?.data?.agent);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const buttons = [
    {
      icon: <AiOutlinePhone className="w-5 h-5" />,
      name: "PHONE",
      action: `tel:${agent?.phone}`,
    },
    {
      icon: <MdOutlineMailOutline className="w-5 h-5" />,
      name: "MAIL",
      action: `mailto:${agent?.email}`,
    },
    {
      icon: <FaWhatsapp className="w-5 h-5" />,
      name: "WHATSAPP",

      action: `https://wa.me/${agent?.phone}`,
    },
  ];

  return (
    <div className="bg-black text-white overflow-hidden bg-[url('https://res.cloudinary.com/dkhns25jh/image/upload/v1732626271/xr_media/pc4opfh5ras7kqcdowao.png')] bg-cover">
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>{agent?.name + "- Xperience Realty | Real Estate Dubai"} </title> */}
        <script type="application/ld+json">
          {JSON.stringify(agent?.schema_org?.properties)}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.2, 0.5]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}
      <div className="grid justify-items-center md:justify-items-start grid-cols-3 py-14 lg:py-28 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
        <div className="grid gap-8 col-span-3 lg:col-span-1 ">
          <div className=" md:flex-row  md:items-center justify-between mb-4 gap-4 ">
            <div className="flex flex-col mr-2 gap-6 ">
              <div className="w-[100vw]">
                <h1 className="font-bold text-3xl md:text-5xl lg:text-5xl text-center md:text-left ">
                  {agent?.name}
                </h1>
                {/* <p className="md:text-md lg:text-2xl text-center md:text-left">
                  {agent?.specialties}
                </p> */}
              </div>
              <div className="flex flex-col gap-4">
                <div className=" flex-col md:flex-row lg:flex-row gap-4 w-full hidden md:flex ">
                  {buttons.map((button, index) => (
                    <a
                      key={index}
                      href={button.action}
                      target={button.name === "WHATSAPP" ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className=" bg-white p-3 px-12 border  hover:bg-black text-black hover:text-white w-auto lg:text-sm xl:text-sm  2xl:text-lg  flex justify-center items-center gap-2"
                    >
                      <p>{button?.icon}</p>
                      <p className="font-semibold">{button?.name}</p>
                    </a>
                  ))}
                </div>

                {/* <div>

                  <ul className="flex md:justify-start text-white gap-4 items-center justify-center ">
                    {socialMediaLinks.map(({ icon: Icon, href }, index) => (
                      <a
                        key={index}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <li>
                          <button
                            className={`border  p-2 bg-white text-black hover:bg-black hover:text-white`}
                          >
                            <Icon className="h-5 w-5" />
                          </button>
                        </li>
                      </a>
                    ))}
                  </ul>
                </div> */}

                <div>
                  <ul className="flex md:justify-start text-white gap-2 items-center justify-center">
                    <li>
                      <a href={agent?.social_links?.youtube}>
                        <img
                          src={YouTubeIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-16"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.facebook}>
                        <img
                          src={FacebookIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-16"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.linkedin}>
                        <img
                          src={LinkedInIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-16"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.instagram}>
                        <img
                          src={InstagramIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-16"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href={agent?.social_links?.twitter}>
                        <img
                          src={TwitterIcon}
                          className="w-[15vw] md:w-[5vw] lg:w-16"
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2  pt-10  px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-white gap-10">
        <div className="grid col-span-2 grid-cols-3 gap-8 lg:w-1/2">
          <div className=" text-black col-span-3 md:col-span-2 lg:col-span-3 ">
            {agent?.experience > 0 && (
              <p className="mb-2 text:lg lg:text-xl ">
                <span className="font-bold">Experience: </span>
                <span className="text-base lg:text-lg">
                  {agent?.experience}
                </span>
              </p>
            )}

            {agent?.specialties && (
              <p className="mb-2 text:lg lg:text-xl ">
                <span className="font-bold">Specialization : </span>
                <span className="text-base lg:text-lg">
                  {agent?.specialties?.join(", ")}
                </span>
              </p>
            )}

            {agent?.education && (
              <p className="mb-2 text:lg lg:text-xl ">
                <span className="font-bold">Education : </span>
                <span className="text-base lg:text-lg">{agent?.education}</span>
              </p>
            )}

            {agent?.languages && (
              <p className="mb-2 text:lg lg:text-xl ">
                <span className="font-bold">Languages : </span>
                <span className="text-base lg:text-lg">
                  {agent?.languages?.join(", ")}
                </span>
              </p>
            )}

            {agent?.bio && (
              <p className="mb-2 text:lg lg:text-xl text-justify ">
                <span className="font-bold">Professional Snapshot : </span>
                <span className="text-base lg:text-lg ">{agent?.bio}</span>
              </p>
            )}

            {agent?.personal_info && (
              <p className="mb-2 text:lg lg:text-xl  text-justify">
                <span className="font-bold">Personal Information : </span>
                <span className="text-base lg:text-lg ">
                  {agent?.personal_info}
                </span>
              </p>
            )}
            <div className="flex flex-col md:flex-row lg:flex-row gap-4 w-full md:hidden ">
              {buttons.map((button, index) => (
                <a
                  key={index}
                  href={button.action}
                  target={button.name === "WHATSAPP" ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className="w-full  p-3 border border-black lg:text-sm xl:text-sm 2xl:text-sm  lg:w-full flex justify-center items-center gap-2 hover:bg-black hover:text-white"
                >
                  {button.icon}
                  <p className="font-semibold hover:bg-black hover:text-white">
                    {button.name}
                  </p>
                </a>
              ))}
            </div>
          </div>

          <div className="object-cover object-top lg:absolute  lg:top-[30%] xl:top-[30%]  2xl:top-[25%]  lg:right-20 xl:right-[6%] 2xl:right-[12%] flex items-center justify-center w-full lg:w-[30%] xl:w-[25%] 2xl:w-[20%] col-span-3 md:col-span-1 order-first md:order-last">
            <img src={agent?.profile_picture} alt={agent?.name} />
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 col-span-2 gap-5 lg:mt-16 4xl:mt-64">
          {agent?.video_links?.map((url) => {
            return (
              url && (
                <div className="relative w-full aspect-video">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={url}
                    volume={0}
                    muted
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
      <div className=" px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-white text-black gap-10 flex flex-col py-10">
        <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl ">
          More of the team
        </h2>
        <StarEmployeesImages data={moreOfTheTeam} />
      </div>
    </div>
  );
}

export default AgentDetails;
