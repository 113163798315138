import React from "react";

const ItemsPerPage = ({ limit, onLimitChange, options = [6, 9, 18, 36, 60, 90] }) => {
  return (
    <div className="flex justify-end items-center">
      <label className="mr-2">Items per page:</label>
      <select
        value={limit}
        onChange={(e) => onLimitChange(Number(e.target.value))}
        className="border px-2 py-1"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ItemsPerPage;
