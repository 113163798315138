import React, {
  useEffect,
  useState,
  lazy,
  Suspense,
  useLayoutEffect,
} from "react";
import { AiOutlineClose } from "react-icons/ai";

import axios from "axios";
import Helmet from "react-helmet";
import { BASE_URL, NEWS_AND_INSIGHTS } from "../api/constants";
import Shimmer from "../components/Shimmer"; // Import Shimmer component
import ProfileIcon from "../components/ProfileIcon";
import { MdOutlineRecommend } from "react-icons/md";
import ReviewsComponent from "../components/ReviewsComponent";
import XploreDubaiNew from "../components/XploreDubaiNew";
import AboutXR from "../components/AboutXR";
import XploreCommunities from "../components/XploreCommunities";

// const InfoPanel = lazy(() => import("../components/InfoPanel"));
// const VideoPlayer = lazy(() => import("../components/VideoPlayer"));

// const Recommendations = lazy(() => import("../components/Recommendations"));
// const Projects = lazy(() => import("../components/Projects"));
// const StarEmployees = lazy(() => import("../components/StarEmployees"));
// const LetXperts = lazy(() => import("../components/LetXperts"));
// const RegisterInterest = lazy(() => import("../components/RegisterInterest"));
// const NewsAndInsight = lazy(() => import("../components/NewsAndInsight"));
// const OurPartners = lazy(() => import("../components/OurPartners"));

import InfoPanel from "../components/InfoPanel";
import VideoPlayer from "../components/VideoPlayer";

import Recommendations from "../components/Recommendations";
import Projects from "../components/Projects";
import StarEmployees from "../components/StarEmployees";
import LetXperts from "../components/LetXperts";
import RegisterInterest from "../components/RegisterInterest";
import NewsAndInsight from "../components/NewsAndInsight";
import OurPartners from "../components/OurPartners";

function Home() {
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [showInfo, setShowInfo] = useState(
    window.innerWidth < 1024 ? false : true
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [data, setData] = useState({});
  const [currentVideo, setCurrentVideo] = useState(null);
  const [recommendationVideos, setRecommendationVideos] = useState([]);
  const [newsAndBlogs, setNewsAndBlogs] = useState([]);
  // const [showModal, setShowModal] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // setShowRecommendations(false);
      setShowInfo(false);
      setTimeElapsed(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const response = await axios.get(BASE_URL);
        const homePageData = response.data;
        setData(homePageData);
        setCurrentVideo(homePageData?.homePageVideos?.mainVideo);
        setRecommendationVideos(homePageData?.homePageVideos?.videos || []);
        setNewsAndBlogs(homePageData?.content || []);
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    };

    // const fetchNewsData = async () => {
    //   try {
    //     const response = await axios.get(NEWS_AND_INSIGHTS);
    //     setNewsAndBlogs(response?.data?.newsAndBlogs || []);
    //   } catch (error) {
    //     console.error("Error fetching news data:", error);
    //   }
    // };

    fetchHomeData();
    // fetchNewsData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleProfileClick = () => setShowInfo(true);
  const handleVideoClick = (clickedVideo) => {
    setCurrentVideo(clickedVideo);
    setRecommendationVideos((prev) =>
      prev.map((video) =>
        video.url === clickedVideo.url ? currentVideo : video
      )
    );
  };
  // const closeModal = () => {
  //   setShowModal(false);
  // };

  const handleCloseClick = () => setShowInfo(false);

  return (
    <div>
      <Helmet>
        <link rel="alternate" hreflang="en-ae" href="https://www.xrealty.ae/" />
        <link rel="alternate" hreflang="en-us" href="https://www.xrealty.ae/" />
        <link rel="alternate" hreflang="en-gb" href="https://www.xrealty.ae/" />
        <link rel="alternate" hreflang="en-sg" href="https://www.xrealty.ae/" />
        <link
          rel="alternate"
          hreflang="en-in"
          href=" https://www.xrealty.ae/"
        />
        <link rel="alternate" hreflang="en" href=" https://www.xrealty.ae/" />
        <link
          rel="alternate"
          hreflang="x-default"
          href=" https://www.xrealty.ae/ "
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Xperience Realty",
            url: "https://www.xrealty.ae/",
            logo: "https://res.cloudinary.com/dkhns25jh/image/upload/v1733821293/xr_media/sqmxma6remiuh1mbv4x7.png",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+97147049400",
                contactType: "sales",
              },
              {
                "@type": "ContactPoint",
                telephone: "+971564080888",
                contactType: "sales",
              },
            ],
            sameAs: [
              "https://www.facebook.com/profile.php?id=61562378023612",
              "https://x.com/xrealty_ae",
              "https://www.instagram.com/xperience.realty",
              "https://www.youtube.com/@xrealtyae",
              "https://www.linkedin.com/company/xrealty-ae",
            ],
          })}
        </script>
      </Helmet>
      {/* 
      <ScrollTriggerModal triggerPoints={[0.3, 0.5]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}
      <div className="pb-3 lg:pb-3">
        <div className="bg-black ">
          <button
            className="text-white px-4 py-3 flex items-center justify-center gap-3 transition-all duration-300 ease-in-out hover:cursor-pointer "
            style={{
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
            onMouseEnter={handleProfileClick}
          >
            <ProfileIcon imageUrl={currentVideo?.agent?.profile_picture} />
            <div className="flex flex-col text-left">
              <h2 className="text-white font-semibold text-base md:text-lg">
                {currentVideo?.agent?.name}
              </h2>
              <h3 className="text-gray-300 text-xs md:text-base">
                {currentVideo?.agent?.specialties}
              </h3>
            </div>
          </button>
        </div>

        <section className="card relative overflow-hidden bg-black text-white flex flex-col lg:flex-row items-center justify-center pbx-8  ">
          <div className="grid grid-cols-1 lg:grid-cols-10 h-full w-full items-center ">
            <div className="relative col-span-1 lg:col-span-7 group h-full flex items-center justify-center ">
              <div className="aspect-video relative w-full h-full">
                <Suspense fallback={<Shimmer />}>
                  <VideoPlayer
                    videoSrc={currentVideo?.url}
                    currentVideo={currentVideo}
                    className="w-full aspect-video object-cover"
                  />
                </Suspense>

                {isMobile ? (
                  <></>
                ) : (
                  // <div className="bg-black w-full h-full border border-black">
                  //   <button
                  //     className="absolute -top-1 -left-2 transform text-black shadow-sm px-3 py-2 flex transition-all duration-300 ease-in-out rounded-full items-center justify-cente gap-3"
                  //     // style={{
                  //     //   backdropFilter: "blur(120px)",
                  //     //   background: "linear-gradient(to bottom, #ff0000 15%, #cc0000 100%)",
                  //     // }}
                  //     onClick={handleProfileClick}
                  //   >
                  //     <ProfileIcon
                  //       imageUrl={currentVideo?.agent?.profile_picture}
                  //     />
                  //     <div className="flex flex-col">
                  //       <h2 className="text-white">
                  //         {currentVideo?.agent?.name}
                  //       </h2>
                  //       <h2 className="text-white">
                  //         {currentVideo?.agent?.specialties}
                  //       </h2>
                  //     </div>
                  //     {/* <span className="text-sm font-regular tracking-wide opacity-75">
                  //     <FaUserAlt className="w-7 h-9 text-white" />
                  //   </span> */}
                  //     {/* <IoIosArrowBack
                  //     style={{ transform: "rotate(0deg)" }}
                  //     className="md:w-6 md:h-6 text-white"
                  //   /> */}
                  //   </button>
                  // </div>

                  // <div className="absolute top-2 left-2  bg-black">
                  //   <button
                  //     className="transform text-white shadow-lg px-4 py-3 flex transition-all duration-300 ease-in-out rounded-lg items-center justify-center gap-3"
                  //     style={{
                  //       backdropFilter: "blur(10px)",
                  //       backgroundColor: "rgba(0, 0, 0, 0.6)", // Translucent black background
                  //     }}
                  //     onClick={handleProfileClick}
                  //   >
                  //     <ProfileIcon
                  //       imageUrl={currentVideo?.agent?.profile_picture}
                  //     />
                  //     <div className="flex flex-col text-left">
                  //       <h2 className="text-white font-semibold text-lg">
                  //         {currentVideo?.agent?.name}
                  //       </h2>
                  //       <h3 className="text-gray-300 text-sm">
                  //         {currentVideo?.agent?.specialties}
                  //       </h3>
                  //     </div>
                  //   </button>
                  // </div>

                  // <button
                  //   className="absolute left-0 top-1/3 transform -translate-y-1/2 bg-transparent text-black px-6 py-3 shadow-sm flex items-center transition-all duration-300 ease-in-out hover:bg-white focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 gap-2"
                  //   style={{
                  //     transform: "rotate(270deg) translateX(-50%)",
                  //     backdropFilter: "blur(8px)",
                  //     background:
                  //       "linear-gradient(to bottom, #ff0000 15%, #cc0000 100%)",
                  //   }}
                  //   onClick={handleProfileClick}
                  //   onMouseOver={(e) => {
                  //     e.currentTarget.style.background =
                  //       "linear-gradient(to bottom, #cc0000 15%, #cc0000 100%)";
                  //   }}
                  //   onMouseOut={(e) => {
                  //     e.currentTarget.style.background =
                  //       "linear-gradient(to bottom, #ff0000 15%, #cc0000 100%)";
                  //   }}
                  // >
                  //   <span className="text-sm text-white font-regular tracking-wide opacity-75">
                  //     AGENT INFO
                  //   </span>
                  //   <IoIosArrowBack
                  //     className="text-white"
                  //     style={{ transform: "rotate(90deg)" }}
                  //   />
                  // </button>

                  <></>
                )}

                <Suspense fallback={<Shimmer />}>
                  <div
                    className={`absolute top-0 -left-1 w-full lg:w-[27vw] h-full bg-gradient-to-r from-black to-transparent transition-transform duration-500 ease-in-out transform z-50 ${
                      showInfo ? "translate-x-0" : "-translate-x-full"
                    }`}
                  >
                    <button
                      className="absolute top-2 right-2 text-white bg-black bg-opacity-50 p-2 rounded-full z-50"
                      onClick={handleCloseClick}
                    >
                      <AiOutlineClose size={24} />
                    </button>
                    <InfoPanel
                      agentInfo={currentVideo?.agent}
                      url={currentVideo?.url}
                    />
                  </div>
                </Suspense>
              </div>
            </div>

            {isMobile ? (
              <div className="col-span-1 p-1  bg-black opacity-60 backdrop-filter backdrop-blur-lg overflow-y-auto lg:col-span-2">
                <Suspense fallback={<Shimmer />}>
                  <Recommendations
                    videos={recommendationVideos}
                    onVideoClick={handleVideoClick}
                  />
                </Suspense>
              </div>
            ) : (
              <div className="lg:col-span-3 flex  h-full flex-col pb-4">
                <div className="flex items-center pl-4 pb-4 gap-2">
                  <h2 className="text-xl ">Recommended Videos </h2>
                  <MdOutlineRecommend className="w-6 h-6" />
                </div>
                <Suspense fallback={<Shimmer />}>
                  <Recommendations
                    videos={recommendationVideos}
                    onVideoClick={handleVideoClick}
                  />
                </Suspense>
              </div>
            )}
          </div>
        </section>
      </div>

      <Suspense fallback={<Shimmer />}>
        <div className="py-1 md:pt-5 lg:pt-7 4xl:pt-8">
          <Projects imagesWithInfo={data?.properties} />
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div>
          <div className="py-8 md:pt-7 lg:pt-10">
            <LetXperts imagesWithInfo={data?.projectOfTheMonth} />
          </div>
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div>
          <div className="md:pt-0 lg:pt-4 ">
            <XploreDubaiNew propertyTypes={data?.propertyTypes} />
          </div>
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div>
          <div className="mt-8 lg:mt-12 py-8 md:pt-4 lg:pt-5 bg-gray-100">
            <AboutXR />
          </div>
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div>
          <div className="pt-6 md:pt-5 lg:pt-10">
            <XploreCommunities communities={data?.communities} />
          </div>
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div className="pt-8 md:pt-6 lg:pt-14">
          <StarEmployees data={data?.agent} />
        </div>
      </Suspense>

      {/* <Suspense fallback={<Shimmer />}>
        <div className="py-8 lg:py-16">
          <XploreDubai sections={sections} />
        </div>
      </Suspense> */}

      <Suspense fallback={<Shimmer />}>
        <div className="pt-8 md:pt-7 lg:pt-12">
          <NewsAndInsight imagesWithInfo={newsAndBlogs} all={false} />
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div>
          <div className="pt-4 md:pt-8 lg:pt-10 4xl:pt-10">
            <RegisterInterest theme="light" />
          </div>
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div className="pt-8 md:pt-5 lg:pt-10  ">
          <ReviewsComponent viewAll={false} />
        </div>
      </Suspense>

      <Suspense fallback={<Shimmer />}>
        <div>
          <div className="py-8 md:py-6 lg:py-10 px-4 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
            <OurPartners />
          </div>
        </div>
      </Suspense>

      <div className="pb-8 pt-8 md:pb-8 md:pt-8 lg:mt-4 lg:pb-8 lg:col-span-6 md:py-3 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-gray-100">
        <h1 className="text-sm md:text-sm lg:text-sm 3xl:text-lg font-semibold mb-2">
          Top Real Estate Company in Dubai
        </h1>
        <p className="text-justify font-light text-sm md:text-sm lg:text-sm 3xl:text-lg leading-relaxed">
          Xperience Realty is a top Dubai Real Estate Company known for making
          Dubai property investment simple and profitable. As one of the top
          real estate agencies in Dubai, we’re focused on providing reliable
          guidance for buyers and investors by helping them find the best luxury
          property in Dubai. Our RERA certified agents bring expertise and a
          client first approach ensuring personalized support throughout the
          buying process.
          <br />
          <br />
          Providing exceptional services, Xperience Realty has established a
          strong track record in a short period, setting high standards across
          the industry. Being consistently ranked among Emaar's top real estate
          brokers in Dubai, we offer exclusive access to freehold properties in
          Dubai like premium apartments, townhouses, and off-plan villas in
          Dubai’s prime areas. Once you approach Xperience Realty for buying
          property in Dubai, our brokers will make an extensive calculation,
          taking into account a wide range of factors, such as location, area
          development, capital appreciation, rental potential, and recreational
          attractions. Our deep market insights into property prices and trends
          set us apart by making us the most preferred choice for both local and
          international investors.
          <br />
          <br /> No matter if you’re searching for premium real estate or
          looking to invest in Dubai, Xperience Realty is your trusted partner
          in Dubai's real estate market. For an easy property purchase in Dubai,
          collaborate with us and experience our expertise by yourself.
        </p>
      </div>

      {isMobile && showInfo && (
        <div className="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center">
          <div className="bg-black bg-opacity-50 overflow-hidden shadow-xl p-4 w-full">
            <button
              className="absolute top-0 right-2 text-white bg-black z-50 p-3"
              onClick={handleCloseClick}
            >
              <AiOutlineClose size={24} />
            </button>
            <Suspense fallback={<Shimmer />}>
              <InfoPanel agentInfo={currentVideo?.agent} />
            </Suspense>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
