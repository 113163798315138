// import React, { useEffect, useState } from "react";
// import { FaChevronDown } from "react-icons/fa";
// import { Link, useLocation } from "react-router-dom";
// import { NEWS_AND_INSIGHTS, WEB_URL } from "../api/constants";
// import axios from "axios";
// import NewsAndInsightsImages from "../components/NewsAndInsightsImages";
// import { Helmet } from "react-helmet";
// import ScrollTriggerModal from "../components/ScrollTriggerModal";
// import LetXpertsInput from "../components/LetXpertsInput";

// function AllNewsInsights() {
//   const [newsAndBlogs, setNewsAndBlogs] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [allNewsAndBlogs, setAllNewsAndBlogs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [selectedMenu, setSelectedMenu] = useState("All");
//   const [dropdownOpen, setDropdownOpen] = useState(false);

//   const { pathname } = useLocation();

//   useEffect(() => {
//     fetchData();
//   }, [currentPage, selectedMenu]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${NEWS_AND_INSIGHTS}?page=${currentPage}&category=${
//           selectedMenu === "All" ? "" : selectedMenu.toLowerCase()
//         }`
//       );
//       setNewsAndBlogs(response?.data?.newsAndBlogs);
//       setCategories(response?.data?.categories);
//       setAllNewsAndBlogs(response?.data?.newsAndBlogs);
//       setTotalPages(response?.data?.totalPages);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleMenuChange = (menu) => {
//     setSelectedMenu(menu);
//     setCurrentPage(1); // Reset to the first page when menu changes
//     setDropdownOpen(false); // Close the dropdown when a menu item is selected
//   };

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };

//   const handlePageChange = (page) => {
//     if (page >= 1 && page <= totalPages) {
//       setCurrentPage(page);
//     }
//   };

//   return (
//     <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] flex flex-col gap-8 mt-10 mb-20">
//       <Helmet>
//         {/* <title>Real Estate News | Xperience Realty | Dubai Real Estate</title> */}
//         <script type="application/ld+json">
//           {JSON.stringify({
//             "@context": "https://schema.org",
//             "@type": "WebSite",
//             name: "Xperience Realty | Real Estate Dubai",
//             description:
//               "Stay informed with the latest Dubai real estate news on our page. Get updated with new trends by subscribing to our updates.",
//             image:
//               "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",

//             url: WEB_URL + pathname.slice(1),
//           })}
//         </script>
//       </Helmet>
//       <ScrollTriggerModal triggerPoints={[0.3, 0.5]}>
//         <LetXpertsInput theme="light" />
//       </ScrollTriggerModal>
//       {/* Dropdown for small screens */}
//       <div className="relative sm:hidden">
//         <button
//           className="w-full outline-none border border-black p-2 bg-white hover:bg-black hover:text-white flex justify-between items-center"
//           onClick={toggleDropdown}
//         >
//           {selectedMenu}
//           <FaChevronDown
//             className={`ml-2 transition-transform ${
//               dropdownOpen ? "rotate-180" : ""
//             }`}
//           />
//         </button>
//         {dropdownOpen && (
//           <div className="absolute w-full bg-white text-white shadow-lg mt-1 z-10">
//             <div
//               className={`p-2 cursor-pointer hover:bg-black ${
//                 selectedMenu === "All"
//                   ? "bg-black text-white"
//                   : "text-black hover:text-white"
//               }`}
//               onClick={() => handleMenuChange("All")}
//             >
//               All
//             </div>
//             {categories.map((menu) => (
//               <div
//                 key={menu}
//                 className={`p-2 cursor-pointer hover:bg-black ${
//                   selectedMenu === menu
//                     ? "bg-black text-white"
//                     : "text-black hover:text-white"
//                 }`}
//                 onClick={() => handleMenuChange(menu)}
//               >
//                 {menu}
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {/* Navbar for larger screens */}
//       <div className="hidden sm:block">
//         <nav className="flex gap-6 justify-center" aria-label="Tabs">
//           <Link
//             key="All"
//             to="#"
//             className={`shrink-0 p-2 text-lg font-medium ${
//               selectedMenu === "All"
//                 ? "text-black"
//                 : "text-gray-500 hover:text-black"
//             }`}
//             onClick={() => handleMenuChange("All")}
//           >
//             ALL
//           </Link>
//           {categories.map((menu) => (
//             <Link
//               key={menu}
//               to="#"
//               className={`shrink-0 p-2 text-lg font-medium ${
//                 selectedMenu === menu
//                   ? "text-black"
//                   : "text-gray-500 hover:text-black"
//               }`}
//               onClick={() => handleMenuChange(menu)}
//             >
//               {menu.toUpperCase()}
//             </Link>
//           ))}
//         </nav>
//       </div>

//       {/* Full-width image */}
//       {/* <img
//         src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724137410/xr_media/va7bdzolp2paowim3fke.jpg"
//         alt=""
//         className="object-cover aspect-video"
//       /> */}
//       <NewsAndInsightsImages imagesWithInfo={newsAndBlogs} all={true} />

//       {/* Pagination */}
//       {/* <div className="flex justify-center mt-6">
//         <button
//           className="mx-2 p-2 border border-black rounded"
//           onClick={() => handlePageChange(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <span className="mx-2 p-2">
//           Page {currentPage} of {totalPages}
//         </span>
//         <button
//           className="mx-2 p-2 border border-black rounded"
//           onClick={() => handlePageChange(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div> */}
//     </div>
//   );
// }

// // return (
// //   <div className="px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[20%] flex flex-col gap-8 mt-10 mb-20">
// //     {/* Dropdown for small screens */}
// //     <div className="relative sm:hidden">
// //       <button
// //         className="w-full outline-none border border-black p-2 bg-white  hover:bg-black hover:text-white flex justify-between items-center"
// //         onClick={toggleDropdown}
// //       >
// //         {selectedMenu}
// //         <FaChevronDown
// //           className={`ml-2 transition-transform ${
// //             dropdownOpen ? "rotate-180" : ""
// //           }`}
// //         />
// //       </button>
// //       {dropdownOpen && (
// //         <div className="absolute w-full bg-white text-white shadow-lg mt-1 z-10">
// //           <div
// //             className={`p-2 cursor-pointer hover:bg-black ${
// //               selectedMenu === "All"
// //                 ? "bg-black text-white"
// //                 : "text-black hover:text-white"
// //             }`}
// //             onClick={() => handleMenuChange("All")}
// //           >
// //             All
// //           </div>
// //           {categories.map((menu) => (
// //             <div
// //               key={menu}
// //               className={`p-2 cursor-pointer hover:bg-black ${
// //                 selectedMenu === menu
// //                   ? "bg-black text-white"
// //                   : "text-black hover:text-white"
// //               }`}
// //               onClick={() => handleMenuChange(menu)}
// //             >
// //               {menu}
// //             </div>
// //           ))}
// //         </div>
// //       )}
// //     </div>

// //     {/* Navbar for larger screens */}
// //     <div className="hidden sm:block">
// //       <nav className="flex gap-6 justify-center" aria-label="Tabs">
// //         <Link
// //           key="All"
// //           to="#"
// //           className={`shrink-0 p-2 text-lg font-medium ${
// //             selectedMenu === "All"
// //               ? "text-black"
// //               : "text-gray-500 hover:text-black"
// //           }`}
// //           onClick={() => handleMenuChange("All")}
// //         >
// //           ALL
// //         </Link>
// //         {categories.map((menu) => (
// //           <Link
// //             key={menu}
// //             to="#"
// //             className={`shrink-0 p-2 text-lg font-medium ${
// //               selectedMenu === menu
// //                 ? "text-black"
// //                 : "text-gray-500 hover:text-black"
// //             }`}
// //             onClick={() => handleMenuChange(menu)}
// //           >
// //             {menu.toUpperCase()}
// //           </Link>
// //         ))}
// //       </nav>
// //     </div>

// //     {/* Full-width image */}
// //     <img
// //       src="https://d2i89gocgiy6bz.cloudfront.net/wp-content/uploads/2024/03/11144444/palm-jumeirah-community.jpeg"
// //       alt=""
// //       className="object-cover h-[20rem] md:h-[35rem] lg:h-[40rem]"
// //     />
// //     <NewsAndInsightsImages imagesWithInfo={newsAndBlogs} all={true} />
// //   </div>
// // );

// export default AllNewsInsights;

import React, { useEffect, useRef, useState } from "react";
import NewsAndInsightsImages from "../components/NewsAndInsightsImages";
import FormatWidget from "../components/FormatWidget";
import axios from "axios";
import { FETCH_BLOGS, NEWS_AND_INSIGHTS, WEB_URL } from "../api/constants";
import ItemsPerPage from "../components/ItemsPerPage";
import Pagination from "../components/Pagination";
import { Helmet } from "react-helmet";
import ScrollTriggerModal from "../components/ScrollTriggerModal";
import LetXpertsInput from "../components/LetXpertsInput";
import { useLocation, useNavigate } from "react-router-dom";

function AllNewsInsights() {
  const [newsAndBlogs, setNewsAndBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(40); // State for items per page
  const [selectedMenu, setSelectedMenu] = useState("All");
  const propertyRef = useRef(null);
  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (propertyRef.current) {
      propertyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    fetchData();

    // Update the URL to reflect the current page and limit
    // const params = new URLSearchParams(search);
    // params.set("page", currentPage); // Update the 'page' query param

    // const limit = parseInt(params.get("limit"), 10) || 40;
    // setItemsPerPage(limit);
    // navigate(`${pathname}?${params.toString()}`, { replace: true });
  }, []);

  // useEffect(() => {
  //   window.location.href =
  //     window.location.href.slice(0, window.location.href.length - 1) +
  //     `?page=${currentPage}&limit=${itemsPerPage}`;
  // }, []);

  // console.log(
  //   window.location.href.slice(0, window.location.href.length - 1) +
  //     `?page=${currentPage}&limit=${itemsPerPage}`
  // );

  // console.log(`${pathname === "/blogs/" ? FETCH_BLOGS : NEWS_AND_INSIGHTS}`);

  const fetchData = async () => {
    try {
      // const response = await axios.get(
      //   `${pathname === "/blogs/" ? FETCH_BLOGS : NEWS_AND_INSIGHTS}`,
      //   {
      //     params: {
      //       page: currentPage,
      //       limit: itemsPerPage,
      //       category: selectedMenu === "All" ? "" : selectedMenu.toLowerCase(),
      //     },
      //   }
      // );

      const response = await axios.get(
        `${pathname === "/blogs/" ? FETCH_BLOGS : NEWS_AND_INSIGHTS}`
      );
      setNewsAndBlogs(
        pathname === "/blogs/"
          ? response.data.blogs
          : response.data.newsAndBlogs
      );
      setCategories(response.data.categories);
      // setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handleLimitChange = (newLimit) => {
  //   setItemsPerPage(newLimit);
  //   setCurrentPage(1); // Reset to the first page when limit changes
  // };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    // Update the limit and reset current page to 1
    const newUrl = `${pathname}?page=${1}&limit=${newLimit}`;
    window.location.href = newUrl; // Navigate and reload
  };

  const handleMenuChange = (menu) => {
    setSelectedMenu(menu);
    setCurrentPage(1); // Reset to the first page when menu changes
  };

  return (
    <div className="pt-16 pb-4 px-4" ref={propertyRef}>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "Xperience Realty | Real Estate Dubai",
            description:
              "Stay informed with the latest Dubai real estate news on our page. Get updated with new trends by subscribing to our updates.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>
      {/* <ScrollTriggerModal triggerPoints={[0.3, 0.5]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal> */}

      <FormatWidget
        title={
          pathname === "/blogs/" ? "Blogs" : "Real Estate News and Insights"
        }
        subtitle={
          pathname === "/blogs/"
            ? "Explore our blog for the latest industry trends and expert suggestions in real estate."
            : "Stay updated with the latest news and insights in the real estate market."
        }
      >
        {/* Items per Page */}
        {/* <ItemsPerPage
          limit={itemsPerPage}
          onLimitChange={handleLimitChange}
          options={[40, 80, 100, 160, 400]}
        /> */}

        {/* Displaying the news and blogs */}
        <NewsAndInsightsImages imagesWithInfo={newsAndBlogs} all={true} />
      </FormatWidget>

      {/* Pagination */}
      {/* <Pagination
        pagePath={pathname}
        currentPage={currentPage}
        totalPages={totalPages}
        limit={itemsPerPage}
        setCurrentPage={setCurrentPage}
      /> */}
    </div>
  );
}

export default AllNewsInsights;
