/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FETCH_All_DEVELOPERS } from "../api/constants";
import xr_logo_white from "../assets/xr_logo_white.png";
import TwitterIcon from "../assets/twitter.png";
import InstagramIcon from "../assets/instagram.png";
import FacebookIcon from "../assets/facebook.png";
import LinkedInIcon from "../assets/linkedin.png";
import YouTubeIcon from "../assets/youtube.png";
import WhatsAppIcon from "../assets/whatsapp.png";
import MailIcon from "../assets/mail.png";
import PhoneIcon from "../assets/phone.png";

const Footer = () => {
  const [developers, setDevelopers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(FETCH_All_DEVELOPERS);
        const developersData = response.data.data;
        setDevelopers(developersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <footer className="bg-black">
      <div className="mx-auto lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] px-4 pb-6 pt-16 sm:px-4 lg:pt-24">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-6">
          <div className="col-span-2">
            <div className="flex justify-start">
              <img className="w-16" src={xr_logo_white} alt="xr-logo-white" />
            </div>
            <p className="mt-6 md:max-w-full leading-5 text-white  w-full lg:w-2/3 font-thin">
              Xperience Realty takes pride in providing our local and overseas
              clients with the highest possible level of service, advice,
              support and assistance with all their property requirements.
            </p>
          </div>

          <div className="text-left">
            <p className="text-base font-bold text-white">DISCOVER</p>
            <ul className="mt-2 space-y-2 text-base">
              {developers?.map((developer, index) => (
                <li key={index}>
                  <a
                    className="transition hover:text-gray-500 text-white font-thin"
                    href={`/developer/${developer.developer_slug}/`}
                  >
                    {developer.developer_name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="text-left col-span-2">
            <p className="text-base font-bold text-white">CONTACT</p>
            <ul className="mt-2 gap-2 flex flex-col space-y-2 text-base">
              <li className="flex   gap-1.5 ltr:sm:justify-start rtl:sm:justify-end w-full lg:w-2/3">
                <a
                  href="https://www.google.com/maps/place/Xperience%E2%80%8B+Realty/@25.1143597,55.2437603,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f69742ae364c3:0xf5f30b07708195fd!8m2!3d25.1143549!4d55.2463352!16s%2Fg%2F11ty7cwknc?entry=tts&shorturl=1"
                  target="_blank"
                  rel="noreferrer"
                  className="flex  gap-1.5"
                >
                  {/* <Icon className="w-6 h-6 text-white mr-3" /> */}
                  <address className="flex-1 not-italic text-white font-thin ">
                    Office no, 301 & 302, Level 3, Building 1, Park Heights
                    Square 1, Dubai Hills Estate, Dubai, UAE PO Box: 65777
                  </address>
                </a>
              </li>
              <li className="flex   gap-5 ltr:sm:justify-start rtl:sm:justify-end">
                <a
                  href="tel:$+97147049400"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-1.5"
                >
                  <img src={PhoneIcon} className="w-5 h-5" alt="" />

                  <address className="flex-1 not-italic text-white font-thin">
                    +97147049400
                  </address>
                </a>
                <a
                  href="https://wa.me/+971564080888"
                  rel="noreferrer"
                  className="flex items-center gap-1.5"
                  target="_blank"
                >
                  <img src={WhatsAppIcon} className="w-6 h-6" alt="" />

                  <address className="flex-1 not-italic text-white font-thin ">
                    +971564080888
                  </address>
                </a>
              </li>
              <li className="flex   gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                <a
                  href="mailto:info@xrealty.ae"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-1.5"
                >
                  <img src={MailIcon} className="w-6   h-5" alt="" />

                  <address className="flex-1 not-italic text-white font-thin ">
                    info@xrealty.ae
                  </address>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="text-base font-bold text-white">SOCIAL MEDIA</p>
            <ul className="mt-2 flex justify-start text-white ">
              <li>
                <a href="https://www.youtube.com/@xrealtyae">
                  <img
                    src={YouTubeIcon}
                    className="w-[10vw] md:w-[5vw] lg:w-[10vw]"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61562378023612">
                  <img
                    src={FacebookIcon}
                    className="w-[10vw] md:w-[5vw] lg:w-[10vw]"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/xrealty-ae">
                  <img
                    src={LinkedInIcon}
                    className="w-[10vw] md:w-[5vw] lg:w-[10vw]"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/xperience.realty">
                  <img
                    src={InstagramIcon}
                    className="w-[10vw] md:w-[5vw] lg:w-[10vw]"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="https://x.com/xrealty_ae">
                  <img
                    src={TwitterIcon}
                    className="w-[10vw] md:w-[5vw] lg:w-[10vw]"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-12 border-t border-white py-6">
          <div className="text-left flex flex-col md:flex-row justify-between ">
            <div className="text-sm text-white flex font-light flex-wrap w-full gap-2">
              <a
                className="inline-block transition hover:cursor-pointer"
                href="/privacy-policy/"
              >
                Privacy Policy
              </a>
              <span> | </span>
              <a
                className="inline-block transition hover:cursor-pointer"
                href="/about-us/"
              >
                About Us
              </a>
              <span> | </span>
              <a
                className="inline-block transition hover:cursor-pointer"
                href="/contact-us-dubai-real-estate-agency/"
              >
                Contact Us
              </a>
              <span> | </span>
              <a
                className="inline-block transition hover:cursor-pointer"
                href="/careers/"
              >
                Careers
              </a>
            </div>
            <p className=" text-sm sm:order-last sm:mt-0 text-white font-light w-full lg:max-w-fit">
              &copy; 2024 Xperience Realty Real Estate. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
