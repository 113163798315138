import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoSrc, currentVideo }) => {

  return (
    <div className="relative w-full aspect-video">
 
      {videoSrc ? (
        <>
          <ReactPlayer
            width="100%"
            height="100%"
            url={videoSrc}
            playing
            volume={0}
            controls={true}
            // light={true}
          />
          
        </>
      ) : (
        <div className="flex items-center justify-center h-full text-white">
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
